import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import NavButtons from "../../components/TrainerApp/Navigation/NavButtons";
import { allRoutes, getPathWithCourseID } from "../../enums/all-routes";
import { customTheme } from "../../components/customTheme";
import Check from "@mui/icons-material/Check";
import { Course, CourseStates } from "../../models/course";
import "../../css/globalVariables.css";
import { useHistory, useParams } from "react-router-dom";
import { getCourseEntity, updateCourse } from "../../api/course-api";
import HeaderCourseDocu from "../../components/TrainerApp/Header/HeaderCourseDocu";
import { SnackBarContext } from "../../context/snackbar-context";
import GeneralDocuPage from "./GeneralDocuPage";
import { HttpStatusCode } from "axios";
import { formatSnackBarErrMsg } from "../../util/SnackBarErrorMsgFormatter";

const CheckOutPage: React.FC = () => {
  const UPDATE_COURSE_DELAY_MILLIS = 500;

  const history = useHistory();
  const snackBarCtx = useContext(SnackBarContext);

  const { courseID } = useParams<{ courseID: string }>();

  const [course, setCourse] = useState<Course>({} as Course);

  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    setIsLoading(true);
    getCourseEntity(courseID, snackBarCtx)
      .then((course: Course) => {
        setCourse(course);
      })
      .finally(() => {
        setIsLoading(false);
        setShowContent(true);
      });
  }, []);
  /* eslint-enable */

  const [isChecked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState(false);

  /** Colors */
  const errColor = "var(--red)";
  const checkedBtnColor = "var(--green)";
  const borderColor = isError ? errColor : "var(--grey)";
  const btnBorderColor = isChecked
    ? "transparent"
    : isError
    ? errColor
    : "var(--orange)";

  const checkHandler = () => {
    setIsChecked(!isChecked);
    if (!isChecked === true) {
      setIsError(false);
    }
  };

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            {showContent && (
              <>
                <ThemeProvider theme={customTheme}>
                  <HeaderCourseDocu
                    course={course}
                    showTopStepper={true}
                    subtitle={"Abschluss"}
                    activeStep={3}
                    backBtnText={"Meine Kurse"}
                    backBtnPath={getPathWithCourseID(
                      courseID,
                      allRoutes.cancelDocu
                    )}
                  ></HeaderCourseDocu>
                  <Box
                    sx={{
                      margin: "20px",
                      marginTop: "150px",
                    }}
                  ></Box>
                  <Box position={"fixed"} bottom={"0"} m={2}>
                    <Box
                      onClick={checkHandler}
                      border={`1px solid ${borderColor}`}
                      borderRadius={"var(--borderRadiusLarge)"}
                    >
                      <Grid container>
                        <button
                          onClick={checkHandler}
                          style={{
                            width: "35px",
                            height: "35px",
                            marginLeft: "10px",
                            marginTop: "10px",
                            backgroundColor: isChecked
                              ? checkedBtnColor
                              : "transparent",
                            border: `1px solid ${btnBorderColor}`,
                            borderRadius: "var(--borderRadiusMedium)",
                          }}
                        >
                          {isChecked && (
                            <Check
                              fontSize="small"
                              style={{
                                stroke: "white",
                                strokeWidth: 2,
                              }}
                            />
                          )}
                        </button>
                      </Grid>
                      <Typography
                        variant="body1"
                        sx={{
                          padding: "0 15px 15px 15px",
                          color: "var(--grey)",
                        }}
                      >
                        {
                          "Hiermit bestätige ich die Richtigkeit und Vollständigkeit meiner Kursdokumentation für diesen Kurs. Mit der Bestätigung schließe ich außerdem die Kursdokumentation ab und genehmige die Aufbewahrung im Archiv."
                        }
                      </Typography>
                      <Box display={"flex"}>
                        {isError && (
                          <Grid
                            container
                            justifyContent={"flex-start"}
                            alignContent={"center"}
                          >
                            <Typography
                              sx={{
                                marginLeft: "15px",
                                fontSize: "var(--p3)",
                                whiteSpace: "nowrap",
                              }}
                              color={errColor}
                            >
                              {"Bitte bestätige die rechtliche Versicherung"}
                            </Typography>
                          </Grid>
                        )}
                      </Box>
                    </Box>

                    <NavButtons
                      leftBtnDisabled={isChecked}
                      leftPathname={getPathWithCourseID(
                        courseID,
                        allRoutes.incident
                      )}
                      leftText={"Zurück"}
                      rightText={"Weiter"}
                      rightBtnOnClick={
                        isChecked
                          ? async () => {
                              setIsLoading(true);
                              const response = await updateCourse(courseID, {
                                checkOutDate: new Date(),
                                state: CourseStates.CHECK_OUT_COMPLETE,
                              });
                              if (response.status !== HttpStatusCode.Ok) {
                                snackBarCtx.openSnackbar(
                                  formatSnackBarErrMsg(
                                    response.status,
                                    "Ein Fehler ist aufgetreten. Bitte versuche es erneut oder lade die Seite neu..."
                                  )
                                );
                                return;
                              }
                              await new Promise<void>((resolve) => {
                                setTimeout(resolve, UPDATE_COURSE_DELAY_MILLIS);
                              }).then(() => {
                                setIsLoading(false);
                                history.push(allRoutes.myCourses);
                              });
                            }
                          : () => {
                              setIsError(true);
                            }
                      }
                      rightBtnDisabled={!isChecked}
                    ></NavButtons>
                  </Box>
                </ThemeProvider>
              </>
            )}
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};

export default CheckOutPage;
