import {
  ListItem,
  ListItemButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { allRoutes, getPathWithCourseID } from "../../../enums/all-routes";
import { Course } from "../../../models/course";
import { CourseStates } from "../../../models/course";
import { customTheme } from "../../customTheme";
import "../../../css/globalVariables.css";
import { getTimeFromDates } from "../../../util/DateFormatHelper";
import CourseItemBtn from "../MyCourses/CourseItemBtn";
import ArchiveItemDialog from "./ArchiveItemDialog";
import mailSVG from "../../../img/mailSVG.svg";
import penSVG from "../../../img/penSVG.svg";

const xIcon = (color: string) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Union">
        <path
          id="Union_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.9961 1.56066C15.1914 1.3654 15.1914 1.04882 14.9961 0.853554L14.289 0.146447C14.0938 -0.0488154 13.7772 -0.0488157 13.5819 0.146446L7.57121 6.15715L1.56066 0.146598C1.3654 -0.0486642 1.04882 -0.0486642 0.853554 0.146598L0.146447 0.853705C-0.0488154 1.04897 -0.0488157 1.36555 0.146446 1.56081L6.157 7.57136L0.146888 13.5815C-0.0483737 13.7767 -0.0483737 14.0933 0.146888 14.2886L0.853995 14.9957C1.04926 15.1909 1.36584 15.1909 1.5611 14.9957L7.57121 8.98558L13.5815 14.9958C13.7767 15.1911 14.0933 15.1911 14.2886 14.9958L14.9957 14.2887C15.191 14.0935 15.191 13.7769 14.9957 13.5816L8.98543 7.57136L14.9961 1.56066Z"
          style={{ fill: color }}
        />
      </g>
    </svg>
  );
};

const ArchiveCourseItem: React.FC<{
  course: Course;
}> = ({ course }) => {
  const history = useHistory();

  const isCourseUndocumented = course.state === CourseStates.UNDOCUMENTED;
  const isCourseCanceled = course.state === CourseStates.CANCELED;

  const [isArchiveItemDialogOpen, setIsArchiveItemDialogOpen] = useState(false);

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <ListItem
            onClick={async () => {
              if (!isCourseUndocumented) {
                setIsArchiveItemDialogOpen(true);
              }
            }}
            sx={{ borderRadius: "0", backgroundColor: "white" }}
          >
            <ListItemButton sx={{ padding: "0", borderRadius: "0" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      maxWidth: "65%",
                      overflow: "hidden",
                    }}
                  >
                    <Typography
                      sx={{
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        padding: "3px 0 3px 0",
                        color: "var(--orange)",
                      }}
                    >
                      {course.courseName}
                    </Typography>
                    <Typography
                      sx={{ fontSize: "var(--h3)", color: "var(--grey)" }}
                    >
                      {course.location}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginTop: "auto",
                      marginRight: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      {course.message &&
                        (isCourseCanceled ? (
                          <CourseItemBtn
                            onClickFct={() => {
                              history.push(
                                getPathWithCourseID(
                                  course.courseID,
                                  allRoutes.notification
                                )
                              );
                            }}
                            backgroundColor={"var(--red)"}
                            iconComponent={xIcon("white")}
                          ></CourseItemBtn>
                        ) : (
                          <>
                            <CourseItemBtn
                              onClickFct={() => {
                                history.push(
                                  getPathWithCourseID(
                                    course.courseID,
                                    allRoutes.notification
                                  )
                                );
                              }}
                              backgroundColor={"var(--orange)"}
                              btnIcon={mailSVG}
                            ></CourseItemBtn>
                          </>
                        ))}
                      {isCourseUndocumented && (
                        <CourseItemBtn
                          onClickFct={() => {}}
                          backgroundColor="var(--red)"
                          btnIcon={penSVG}
                        ></CourseItemBtn>
                      )}
                    </div>
                    <Typography
                      sx={{
                        fontSize: "var(--h3)",
                        color: "var(--grey)",
                        textAlign: "right",
                        paddingRight: "5px",
                      }}
                    >
                      {getTimeFromDates(course.startDate, course.endDate)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </ListItemButton>
          </ListItem>
        </div>
      </ThemeProvider>
      {isArchiveItemDialogOpen && (
        <ArchiveItemDialog
          course={course}
          isOpen={isArchiveItemDialogOpen}
          onCloseDialog={() => {
            setIsArchiveItemDialogOpen(false);
          }}
        ></ArchiveItemDialog>
      )}
    </>
  );
};
export default ArchiveCourseItem;
