import { Typography } from "@mui/material";
import { IncidentTypes } from "../../../pages/TrainerApp/Incident.page";
import "../../../css/globalVariables.css";

const IncidentTypeBtn: React.FC<{
  incidentType: IncidentTypes;
  onClickHandler: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ incidentType, onClickHandler }) => {
  const buttonStyle = {
    backgroundColor: "var(--orange)",
    border: "none",
    margin: "0 10px 10px 10px",
    borderRadius: "var(--borderRadiusMedium)",
  };

  const buttonTextStyle = {
    color: "white",
    fontSize: "var(--h4)",
    fontWeight: "bold",
    padding: "15px",
    textTransform: "uppercase",
  };

  return (
    <button style={buttonStyle} onClick={onClickHandler}>
      <Typography sx={buttonTextStyle}>{incidentType}</Typography>
    </button>
  );
};

export default IncidentTypeBtn;
