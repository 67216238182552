import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const TextFieldWithChars: React.FC<{
  placeholder: string;
  minChars: number;
  maxChars: number;
  rows: number;
  onChange: (value: string) => void;
  focusHandler?: () => void;
  blurHandler?: () => void;
  error?: boolean;
}> = ({
  placeholder,
  minChars,
  maxChars,
  rows,
  onChange,
  focusHandler,
  blurHandler,
  error,
}) => {
  const [text, setText] = useState("");

  /* eslint-disable */
  useEffect(() => {
    onChange(text);
  }, [text]);
  /* eslint-enable */

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  return (
    <TextField
      error={error}
      onFocus={focusHandler}
      onBlur={blurHandler}
      multiline
      rows={rows}
      sx={{ width: "100%", marginTop: "20px" }}
      placeholder={placeholder}
      variant="outlined"
      value={text}
      onChange={handleChange}
      inputProps={{ maxLength: maxChars }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              color: text.length < minChars ? "var(--red)" : "var(--green)",
              fontSize: "10px",
            }}
          >
            {text.length} / {maxChars} {"Zeichen"}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default TextFieldWithChars;
