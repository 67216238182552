import { useContext, useEffect, useState } from "react";
import BottomNavigationBar from "../../components/TrainerApp/Navigation/BottomNavigationBar";
import { ThemeProvider } from "@emotion/react";
import { customTheme } from "../../components/customTheme";
import { Box, Typography } from "@mui/material";
import { Course } from "../../models/course";
import { getArchiveCourses } from "../../api/course-api";
import "../../css/globalVariables.css";
import InfoCardTypography from "../../components/TrainerApp/InfoCard/InfoCardTypography";
import { HttpStatusCode } from "axios";
import { SnackBarContext } from "../../context/snackbar-context";
import ArchiveCourseDay from "../../components/TrainerApp/Archive/ArchiveCourseDay";
import LoadingScreen from "../../components/LoadingScreen";
import SnackbarMessage from "../../components/Snackbar/SnackbarMessage";
import { localStorageKeys } from "../../constants/storage-keys";

const ArchivPage: React.FC = () => {
  const snackBarCtx = useContext(SnackBarContext);
  const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);

  const [archiveCourses, setArchiveCourses] = useState<
    Record<string, Course[]>
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const loadArchiveCourses = async () => {
    setIsLoading(true);
    if (trainerUUID) {
      getArchiveCourses(trainerUUID, 0)
        .then((data) => {
          if (data.status !== HttpStatusCode.Ok) {
            snackBarCtx.openSnackbar(
              `${data.status} | Ein Fehler ist aufgetreten bitte lade die Seite neu`
            );
            return;
          }
          if (Object.keys(data).length === 0) {
            setErrorMsg(
              "Es konnten keine archivierten Kurse gefunden werden..."
            );
            return;
          }
          setArchiveCourses(data.courses);
          setErrorMsg("");
        })
        .catch(() => {
          snackBarCtx.openSnackbar(
            "Ein Fehler ist aufgetreten bitte prüfe deine Internetverbindung und lade die Seite neu."
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /* eslint-disable */
  useEffect(() => {
    loadArchiveCourses();
  }, []);
  /* eslint-enable */

  return (
    <>
      <LoadingScreen isLoading={isLoading}></LoadingScreen>
      <SnackbarMessage
        open={snackBarCtx.isOpen}
        text={snackBarCtx.text}
        onCloseFct={snackBarCtx.closeSnackbar}
      ></SnackbarMessage>

      <ThemeProvider theme={customTheme}>
        <Box sx={{ margin: "20px", marginTop: "64px", marginBottom: "80px" }}>
          <Typography
            sx={{
              color: "var(--grey)",
              fontWeight: "bold",
              fontSize: "var(--h0)",
            }}
          >
            {"Archiv"}
          </Typography>
          {errorMsg !== "" && (
            <Box sx={{ marginTop: "3rem" }}>
              <InfoCardTypography text={errorMsg}></InfoCardTypography>
            </Box>
          )}
          {Object.keys(archiveCourses).map((key) => (
            <ArchiveCourseDay
              key={key}
              date={key}
              courses={archiveCourses[key]}
            ></ArchiveCourseDay>
          ))}
        </Box>
      </ThemeProvider>
      <BottomNavigationBar></BottomNavigationBar>
    </>
  );
};

export default ArchivPage;
