import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { allRoutes, getPathWithCourseID } from "../../enums/all-routes";
import { Participant } from "../../models/participant";
import NavButtons from "../../components/TrainerApp/Navigation/NavButtons";
import { Box, ThemeProvider } from "@mui/material";
import { customTheme } from "../../components/customTheme";
import { Course } from "../../models/course";
import HeaderCourseDocu from "../../components/TrainerApp/Header/HeaderCourseDocu";
import MissingAttendeesDialog from "../../components/TrainerApp/Attendance/MissingAttendeesDialog";
import AttendeeDetailDialog from "../../components/TrainerApp/Attendance/AttendeeDetailDialog";
import { getCourseEntity, updateCourse } from "../../api/course-api";
import AttendanceList from "../../components/TrainerApp/Attendance/AttendanceList";
import { TIMEOUT_MILLIS } from "../../constants/timeout-duration";
import { SnackBarContext } from "../../context/snackbar-context";
import GeneralDocuPage from "./GeneralDocuPage";

const AttendancePage: React.FC = () => {
  const snackBarCtx = useContext(SnackBarContext);
  // States
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const [courseObj, setCourseObj] = useState<Course>({} as Course);
  const [participants, setParticipants] = useState<Participant[]>(
    [] as Participant[]
  );

  const { courseID } = useParams<{ courseID: string }>();

  const [participantObj, setParticipantObj] = useState<Participant>(
    {} as Participant
  );
  const [trueParticipants, setTrueParticipants] = useState([] as Participant[]);
  const [falseParticipants, setFalseParticipants] = useState(
    [] as Participant[]
  );

  const [showMissingAttendeesDialog, setShowMissingAttendeesDialog] =
    useState(false);
  const [dialogIsLoading, setDialogIsLoading] = useState(false);
  const [showAttendeeDetailDialog, setShowAttendeeDetailDialog] =
    useState(false);

  // Functions
  const participantValueChangeHandler = (id: string, value: boolean) => {
    if (Object.keys(participants).length !== 0) {
      const index = participants.findIndex((p: Participant) => p.id === id);
      const updatedParticipants = [...participants];
      updatedParticipants[index].isPresent = value;
      setParticipants(updatedParticipants);
    }
  };
  const sortParticipants = (participants: Participant[]) => {
    return participants.sort((a, b) => {
      const firstNameA = a.firstName.toLowerCase();
      const firstNameB = b.firstName.toLowerCase();

      if (firstNameA < firstNameB) {
        return -1;
      }
      if (firstNameA > firstNameB) {
        return 1;
      }
      return 0;
    });
  };

  const filterAndSort = (participants: Participant[], isPresent: boolean) => {
    return participants
      .filter((t: Participant) => t.isPresent === isPresent)
      .sort((a, b) => {
        const firstNameA = a.firstName.toLowerCase();
        const firstNameB = b.firstName.toLowerCase();

        if (firstNameA < firstNameB) {
          return -1;
        }
        if (firstNameA > firstNameB) {
          return 1;
        }
        return 0;
      });
  };

  const openInfoHandler = (participantID: string) => {
    setShowAttendeeDetailDialog(true);
    const openParticipant: Participant | undefined = participants.find(
      (p: Participant) => p.id === participantID
    );
    if (openParticipant) {
      setParticipantObj(openParticipant);
    }
  };

  const saveParticipantsDB = async () => {
    if (courseObj.courseName === "TESTKURS") {
      return true;
    }
    setIsLoading(true);
    const response = await updateCourse(courseID, {
      participants: participants,
    });
    setIsLoading(false);
    if (response.status === 200) {
      return true;
    } else {
      snackBarCtx.openSnackbar(
        `${response.status} | Ein Fehler ist aufgetreten. Bitte versuche es erneut`
      );
      return false;
    }
  };

  // Effects
  useEffect(() => {
    setTrueParticipants(filterAndSort(participants, true));
    setFalseParticipants(filterAndSort(participants, false));
  }, [participants]);

  /* eslint-disable */
  useEffect(() => {
    setIsLoading(true);
    getCourseEntity(courseID, snackBarCtx)
      .then((c: Course) => {
        setCourseObj(c);
        setParticipants(sortParticipants(c.participants));
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
          setShowContent(true);
        }, TIMEOUT_MILLIS);
      });
  }, [courseID]);
  /* eslint-enable */

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            {showContent && (
              <>
                <ThemeProvider theme={customTheme}>
                  <HeaderCourseDocu
                    course={courseObj}
                    showTopStepper={true}
                    subtitle={"Vorbereitung"}
                    activeStep={2}
                    backBtnText={"Meine Kurse"}
                    backBtnPath={getPathWithCourseID(
                      courseID,
                      allRoutes.cancelDocu
                    )}
                    backBtnOnClick={async () => {
                      await saveParticipantsDB();
                    }}
                  ></HeaderCourseDocu>
                  <Box
                    sx={{
                      margin: "20px",
                      marginTop: "150px",
                    }}
                  ></Box>
                </ThemeProvider>
                <AttendanceList
                  trueParticipantsList={trueParticipants}
                  falseParticipantsList={falseParticipants}
                  onValueChange={participantValueChangeHandler}
                  onClickInfoBtn={openInfoHandler}
                  isModal={false}
                ></AttendanceList>

                <NavButtons
                  leftBtnOnClick={async () => {
                    const success = await saveParticipantsDB();
                    if (success) {
                      history.push(
                        getPathWithCourseID(courseID, allRoutes.condition)
                      );
                    }
                  }}
                  leftText={"Zurück"}
                  rightPathname={""}
                  rightBtnOnClick={() => {
                    setShowMissingAttendeesDialog(true);
                    setFalseParticipants(
                      participants.filter((t) => t.isPresent === false)
                    );
                  }}
                  rightText={"Weiter"}
                ></NavButtons>

                <AttendeeDetailDialog
                  courseObj={courseObj}
                  participantObj={participantObj}
                  isOpen={showAttendeeDetailDialog}
                  closeDialog={() => setShowAttendeeDetailDialog(false)}
                  backBtnText="Teilnehmende"
                  backBtnPath={getPathWithCourseID(
                    courseID,
                    allRoutes.attendance
                  )}
                />

                <MissingAttendeesDialog
                  courseObj={courseObj}
                  isLoading={dialogIsLoading}
                  falseParticipants={falseParticipants}
                  showDialog={showMissingAttendeesDialog}
                  openDetailBtn={(participantID) => {
                    openInfoHandler(participantID);
                  }}
                  closeBtn={() => setShowMissingAttendeesDialog(false)}
                  nextBtn={async () => {
                    setDialogIsLoading(true);
                    const success = await saveParticipantsDB();
                    setDialogIsLoading(false);
                    if (success) {
                      history.push(
                        getPathWithCourseID(courseID, allRoutes.incident)
                      );
                      setShowMissingAttendeesDialog(false);
                    }
                  }}
                />
              </>
            )}
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};

export default AttendancePage;
