import { Course, CourseStates } from "../models/course";
import { Incident } from "../models/incident";
import { Participant } from "../models/participant";
import { groupAndSortCourses } from "../util/CourseUtilFunctions";
import { getTrainerAxiosInstance } from "./api-config";
import { Attachment } from "../models/attachment";
import { HttpStatusCode } from "axios";
import { SnackBarContextObj } from "../context/snackbar-context";
import { formatSnackBarErrMsg } from "../util/SnackBarErrorMsgFormatter";

export async function addTestCourse(trainerUUID: string) {
  const axiosInstance = getTrainerAxiosInstance();
  await axiosInstance.post(`/courses/add-course`, {
    trainerUUID: trainerUUID,
  });
}

export async function getArchiveCourses(
  trainerUUID: string,
  offset: number
): Promise<{
  status: number;
  courses: Record<string, Course[]>;
}> {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(`/courses/archive`, {
    params: { trainerUUID, offset },
  });
  const archiveCourses: Course[] = response.data;
  const groupedCourses: Record<string, Course[]> = groupAndSortCourses(
    archiveCourses,
    false
  );
  return { status: response.status, courses: groupedCourses };
}

export async function getTrainerCourses(trainerUUID: string) {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(`/courses?uuid=${trainerUUID}`);

  if (response.status === HttpStatusCode.Ok) {
    const trainerCourses: Course[] = response.data.courses;
    const groupedTrainerCourses: Record<string, Course[]> =
      groupAndSortCourses(trainerCourses);

    return {
      status: response.status,
      courses: groupedTrainerCourses,
    };
  } else {
    return {
      status: response.status,
      message: response.data.message,
    };
  }
}

export async function getCourseEntity(
  courseID: string,
  snackBarCtx: SnackBarContextObj
) {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(`/courses/${courseID}`);
  let returnCourse: Course;
  if (response.status === HttpStatusCode.Ok) {
    const courseData = response.data;
    returnCourse = new Course(
      courseData.id,
      courseData.courseID,
      courseData.courseName,
      courseData.state,
      courseData.startDate,
      courseData.endDate,
      courseData.checkInDate,
      courseData.checkOutDate,
      courseData.location,
      courseData.documentingTrainerUUID,
      courseData.trainers,
      courseData.participants,
      courseData.isLateDocu,
      courseData.hallConditionValue,
      courseData.materialConditionValue,
      courseData.trainerConditionValue,
      courseData.hallConditionDescr,
      courseData.hallConditionAttachments.map(
        (a: Attachment) => new Attachment(a.link)
      ),
      courseData.materialConditionDescr,
      courseData.materialConditionAttachments.map(
        (a: Attachment) => new Attachment(a.link)
      ),
      courseData.trainerConditionDescr,
      courseData.message
    );
    returnCourse.incidents = courseData.incidents.map((i: Incident) => {
      return {
        ...i,
        attachments: i.attachments.map((obj) => {
          return new Attachment(obj.link);
        }),
      };
    });
  } else {
    snackBarCtx.openSnackbar(
      formatSnackBarErrMsg(
        response.status,
        `Beim laden des Kurses ist ein Fehler aufgetreten. Bitte versuche es erneut... | courseID: ${courseID}`
      )
    );
  }
  return returnCourse;
}

export async function cancelCourseDocu(course: Course) {
  const courseID: string = course.courseID;
  const currentParticipants: Participant[] = course.participants;
  const defaultParticipants: Participant[] = currentParticipants.map(
    (participant: Participant) => {
      return { ...participant, isPresent: false };
    }
  );

  const axiosInstance = getTrainerAxiosInstance();

  const resetCourseAttr: Record<string, any> = {
    state: CourseStates.READY_FOR_CHECK_IN,
    documentingTrainerUUID: "",
    hallConditionValue: false,
    hallConditionDescr: "",
    hallConditionAttachments: [],
    materialConditionValue: false,
    materialConditionDescr: "",
    materialConditionAttachments: [],
    trainerConditionValue: false,
    trainerConditionDescr: "",
    message: "",
    participants: defaultParticipants,
    incidents: [],
    checkInDate: null,
    checkOutDate: null,
  };

  for (const attachment of course.hallConditionAttachments) {
    await deleteConditionAttachment(courseID, attachment.link, {
      hallCondition: true,
    });
  }

  for (const attachment of course.materialConditionAttachments) {
    await deleteConditionAttachment(courseID, attachment.link, {
      materialCondition: true,
    });
  }

  for (const incident of course.incidents) {
    if (incident.attachments) {
      for (const attachment of incident.attachments) {
        await deleteIncidentAttachment(
          courseID,
          incident.incidentUUID,
          attachment.link
        );
      }
    }
    await deleteIncidentDB(courseID, incident.incidentUUID);
  }

  await axiosInstance.put(`/courses/${courseID}`, {
    updateAttr: resetCourseAttr,
  });
}

export async function updateCourse(
  courseID: string,
  courseUpdateAttr: Record<string, any>
): Promise<{
  status: number;
  courseID?: string;
  error?: string;
}> {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.put(`/courses/${courseID}`, {
    updateAttr: courseUpdateAttr,
  });
  return response.data;
}

export async function deleteIncidentDB(courseID: string, incidentUUID: string) {
  const axiosInstance = getTrainerAxiosInstance();
  await axiosInstance.delete(`/courses/${courseID}/incidents/${incidentUUID}`);
}

export async function deleteIncidentAttachment(
  courseID: string,
  incidentUUID: string,
  filename: string
) {
  const axiosInstance = getTrainerAxiosInstance();
  await axiosInstance.delete(
    `/courses/${courseID}/incidents/${incidentUUID}/attachment?filename=${filename}`
  );
}

type ConditionTypes = {
  hallCondition?: boolean;
  materialCondition?: boolean;
};

export async function saveConditionImages(
  files: File[],
  courseID: string,
  conditions: ConditionTypes
) {
  const axiosInstance = getTrainerAxiosInstance();

  let formData = new FormData();
  for (const file of files) {
    formData.append("files", file);
  }

  const queryString: string = Object.keys(conditions)
    .filter((conditionKey) => conditions[conditionKey as keyof ConditionTypes])
    .toString();

  try {
    const response = await axiosInstance.put(
      `/courses/${courseID}/condition-attachment?${queryString}=true`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        maxBodyLength: Infinity,
      }
    );

    return response;
  } catch (error) {
    console.error("Error uploading files:", error);
    throw error;
  }
}

export async function addUpdateIncident(
  courseID: string,
  incident: Incident,
  files: File[]
) {
  const axiosInstance = getTrainerAxiosInstance();

  let formData = new FormData();
  for (const file of files) {
    formData.append("files", file);
  }

  const incidentData = {
    incidentUUID: incident.incidentUUID,
    incidentType: incident.incidentType,
    description: incident.description,
  };

  formData.append("incidentData", JSON.stringify(incidentData));

  return await axiosInstance.put(`/courses/${courseID}/incidents`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    maxBodyLength: Infinity,
  });
}

export async function deleteConditionAttachment(
  courseID: string,
  s3Filename: string,
  conditionType: ConditionTypes
) {
  const axiosInstance = getTrainerAxiosInstance();

  const queryString: string = Object.keys(conditionType)
    .filter(
      (conditionKey) => conditionType[conditionKey as keyof ConditionTypes]
    )
    .toString();

  await axiosInstance.delete(
    `/courses/${courseID}/condition-attachment?filename=${s3Filename}&${queryString}=true`
  );
}

export async function deleteCourse(id: string) {
  const axiosInstance = getTrainerAxiosInstance();
  return await axiosInstance.delete(`/courses/admin/${id}`);
}

export async function getParticipantsByCourseName(courseName: string) {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(
    `/participants?courseName=${courseName}`
  );
  if (response.status === HttpStatusCode.Ok) {
    const participantData = response.data.participants;
    return participantData;
  }
}
