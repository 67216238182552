import { Dialog } from "@mui/material";
import { Course } from "../../../models/course";
import InfoCard from "../InfoCard/InfoCard";
import { allRoutes, getPathWithCourseID } from "../../../enums/all-routes";
import { Participant } from "../../../models/participant";
import InfoCardYesNoBtns from "../InfoCard/InfoCardYesNoBtns";
import InfoCardMissingAttendeesContent from "../InfoCard/InfoCardMissingAttendeesContent";
import "../../../css/globalVariables.css";
import InfoCardTypography from "../InfoCard/InfoCardTypography";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../LoadingScreen";

const MissingAttendeesDialog: React.FC<{
  courseObj: Course;
  isLoading: boolean;
  falseParticipants: Participant[];
  showDialog: boolean;
  openDetailBtn: (_: string) => void;
  closeBtn: React.MouseEventHandler<HTMLButtonElement>;
  nextBtn: React.MouseEventHandler<HTMLButtonElement>;
}> = ({
  courseObj,
  isLoading,
  falseParticipants,
  showDialog,
  openDetailBtn,
  closeBtn,
  nextBtn,
}) => {
  const { courseID } = useParams<{ courseID: string }>();

  return (
    <>
      <Dialog fullScreen open={showDialog} keepMounted>
        <LoadingScreen isLoading={isLoading}></LoadingScreen>
        <InfoCard
          courseObj={courseObj}
          backBtnText={courseObj.courseName}
          backBtnPath={getPathWithCourseID(courseID, allRoutes.cancelDocu)}
          content={
            falseParticipants.length !== 0 ? (
              <InfoCardMissingAttendeesContent
                participants={falseParticipants}
                infoHandler={(participantID: string) => {
                  openDetailBtn(participantID);
                }}
              ></InfoCardMissingAttendeesContent>
            ) : (
              <InfoCardTypography text={"Alle Teilnehmenden sind anwesend"} />
            )
          }
          btnChildren={
            <InfoCardYesNoBtns
              yesBtnText={"Weiter"}
              noBtnText={"Zurück"}
              yesBtnOnClick={nextBtn}
              noBtnOnClick={closeBtn}
            ></InfoCardYesNoBtns>
          }
        ></InfoCard>
      </Dialog>
    </>
  );
};

export default MissingAttendeesDialog;
