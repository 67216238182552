import { Box } from "@mui/material";
import "../../../css/globalVariables.css";

const InfoCardYesNoBtns: React.FC<{
  yesBtnText: string;
  noBtnText: string;
  yesBtnOnClick: React.MouseEventHandler<HTMLButtonElement>;
  noBtnOnClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ yesBtnText, noBtnText, yesBtnOnClick, noBtnOnClick }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <button
        style={{
          width: "50%",
          color: "white",
          fontWeight: "bold",
          borderRadius: "0 0 0 var(--borderRadiusLarge)",
          border: "none",
          borderRight: "1px solid white",
          backgroundColor: "var(--orange)",
          height: "40px",
          boxShadow: "none",
          padding: "10px",
        }}
        onClick={noBtnOnClick}
      >
        {noBtnText}
      </button>
      <button
        style={{
          width: "49.9%",
          color: "white",
          fontWeight: "bold",
          borderRadius: "0 0 var(--borderRadiusLarge) 0",
          border: "none",
          backgroundColor: "var(--orange)",
          height: "40px",
          boxShadow: "none",
          padding: "10px",
        }}
        onClick={yesBtnOnClick}
      >
        {yesBtnText}
      </button>
    </Box>
  );
};

export default InfoCardYesNoBtns;
