import { useContext, useEffect, useState } from "react";
import NavButtons from "../../components/TrainerApp/Navigation/NavButtons";
import { allRoutes, getPathWithCourseID } from "../../enums/all-routes";
import { Incident } from "../../models/incident";
import { Box, Dialog, ThemeProvider } from "@mui/material";
import { customTheme } from "../../components/customTheme";
import HeaderCourseDocu from "../../components/TrainerApp/Header/HeaderCourseDocu";
import IncidentItem from "../../components/TrainerApp/Incident/IncidentItem";
import AddIncidentBtn from "../../components/TrainerApp/Incident/AddIncidentBtn";
import { Course } from "../../models/course";
import { useParams } from "react-router-dom";
import { deleteIncidentDB, getCourseEntity } from "../../api/course-api";
import { TIMEOUT_MILLIS } from "../../constants/timeout-duration";
import InfoCard from "../../components/TrainerApp/InfoCard/InfoCard";
import InfoCardTypography from "../../components/TrainerApp/InfoCard/InfoCardTypography";
import InfoCardYesNoBtns from "../../components/TrainerApp/InfoCard/InfoCardYesNoBtns";
import { SnackBarContext } from "../../context/snackbar-context";
import GeneralDocuPage from "./GeneralDocuPage";

export enum IncidentTypes {
  INJURY = "VERLETZUNG",
  DAMAGE = "SCHADEN",
  OTHER = "SONSTIGES",
}

const IncidentPage: React.FC = () => {
  const snackBarCtx = useContext(SnackBarContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const [course, setCourse] = useState<Course>({} as Course);
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [showIncidentSelection, setShowIncidentSelection] = useState(false);
  const [showAddIncidentBtn, setShowAddIncidentBtn] = useState(true);
  const [isAllSaved, setIsAllSaved] = useState(true);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteIncidentUUID, setDeleteIncidentUUID] = useState("");

  const [navBtnVisible, setNavBtnVisible] = useState(true);

  /* eslint-disable */
  const { courseID } = useParams<{ courseID: string }>();

  const reloadCourseDB = async () => {
    setIsLoading(true);

    const courseInDB: Course = await getCourseEntity(courseID, snackBarCtx);
    setCourse(courseInDB);
    setIncidents(
      courseInDB.incidents.map((obj) => {
        return new Incident(
          obj.incidentUUID,
          obj.incidentType,
          obj.courseID,
          obj.description,
          obj.attachments,
          true
        );
      })
    );
    setTimeout(() => {
      setIsLoading(false);
      setShowContent(true);
    }, TIMEOUT_MILLIS);
  };

  useEffect(() => {
    const getCourse = async () => {
      await reloadCourseDB();
    };

    getCourse().catch(() => {
      setIsLoading(false);
      setShowContent(true);
    });
  }, []);

  useEffect(() => {
    setIsAllSaved(incidents.every((i) => i.isSaved === true));
  }, [incidents]);
  /* eslint-enable */

  if (!course) {
    return null;
  }

  const handleIncidentChange = async (incidentUUID: string) => {
    const incidentToUpdate = incidents.find(
      (i) => i.incidentUUID === incidentUUID
    );
    if (incidentToUpdate) {
      incidentToUpdate.isSaved = false;
    }
    const updatedIncidents = [...incidents];
    const indexToUpdate = incidents.findIndex(
      (i) => i.incidentUUID === incidentUUID
    );
    if (indexToUpdate !== -1) {
      updatedIncidents[indexToUpdate] = incidentToUpdate;
    }

    setIncidents(updatedIncidents);
  };

  const handleDeleteIncident = async (incidentUUID: string) => {
    const filteredIncidents: Incident[] = incidents.filter(
      (incident: Incident) => incident.incidentUUID !== incidentUUID
    );
    setIncidents(filteredIncidents);
    await deleteIncidentDB(courseID, incidentUUID);
    await reloadCourseDB();
  };

  const startLoadingHandler = () => {
    setIsLoading(true);
  };
  const stopLoadingHandler = () => {
    setIsLoading(false);
  };

  const handleInputFocus = () => {
    setNavBtnVisible(false);
  };

  const handleInputBlur = () => {
    setNavBtnVisible(true);
  };

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            {showContent && (
              <>
                <ThemeProvider theme={customTheme}>
                  <HeaderCourseDocu
                    course={course}
                    showTopStepper={true}
                    subtitle={"Vorkommnisse"}
                    activeStep={3}
                    backBtnText={"Meine Kurse"}
                    backBtnPath={getPathWithCourseID(
                      courseID,
                      allRoutes.cancelDocu
                    )}
                  ></HeaderCourseDocu>
                  <Box
                    sx={{
                      margin: "20px",
                      marginTop: "150px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      margin: "20px",
                    }}
                  >
                    {incidents &&
                      incidents.map((i: Incident) => (
                        <IncidentItem
                          courseID={courseID}
                          courseName={course.courseName}
                          key={i.incidentUUID}
                          incident={i}
                          onChangeIncident={async (incidentUUID: string) => {
                            handleIncidentChange(incidentUUID);
                          }}
                          onDeleteIncident={async (incidentUUID: string) => {
                            setDeleteIncidentUUID(incidentUUID);
                            setShowConfirmationDialog(true);
                          }}
                          onSaveIncident={async () => {
                            await reloadCourseDB();
                          }}
                          onStartLoading={startLoadingHandler}
                          onStopLoading={stopLoadingHandler}
                          onFocusHandler={handleInputFocus}
                          onBlurHandler={handleInputBlur}
                        />
                      ))}
                    {showAddIncidentBtn && (
                      <AddIncidentBtn
                        courseID={courseID}
                        showIncidentSelection={showIncidentSelection}
                        onCreateIncident={(newIncident: Incident) => {
                          setIncidents((tmpIncidents: Incident[]) => {
                            return [...tmpIncidents, newIncident];
                          });
                        }}
                        onClickPlusBtn={() => {
                          if (incidents.length === 0) {
                            setShowIncidentSelection(true);
                          } else if (isAllSaved) {
                            setShowIncidentSelection(true);
                          }
                        }}
                        onClickTrashBtn={() => {
                          setShowIncidentSelection(false);
                        }}
                        onClickIncidentTypeBtn={() => {
                          setShowIncidentSelection(false);
                          setShowAddIncidentBtn(true);
                        }}
                      ></AddIncidentBtn>
                    )}
                  </Box>
                </ThemeProvider>

                {navBtnVisible && (
                  <NavButtons
                    leftPathname={
                      isAllSaved
                        ? getPathWithCourseID(courseID, allRoutes.attendance)
                        : undefined
                    }
                    leftText={"Zurück"}
                    leftBtnDisabled={!isAllSaved}
                    rightPathname={
                      isAllSaved
                        ? getPathWithCourseID(courseID, allRoutes.checkOut)
                        : undefined
                    }
                    rightText={"Weiter"}
                    rightBtnDisabled={!isAllSaved}
                  ></NavButtons>
                )}

                <Dialog fullScreen open={showConfirmationDialog} keepMounted>
                  <InfoCard
                    courseObj={course}
                    backBtnText={course.courseName}
                    backBtnPath={allRoutes.myCourses}
                    content={
                      <InfoCardTypography
                        text={
                          "Bist du sicher, dass du das Vorkommnis löschen möchtest?"
                        }
                      />
                    }
                    btnChildren={
                      <InfoCardYesNoBtns
                        yesBtnText={"Ja"}
                        noBtnText={"Nein"}
                        yesBtnOnClick={() => {
                          handleDeleteIncident(deleteIncidentUUID);
                          setShowConfirmationDialog(false);
                        }}
                        noBtnOnClick={() => {
                          setShowConfirmationDialog(false);
                          setIsLoading(false);
                        }}
                      ></InfoCardYesNoBtns>
                    }
                  ></InfoCard>
                </Dialog>
              </>
            )}
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};

export default IncidentPage;
