export interface TrainerInterface {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isAdmin: boolean;
  isActivated: boolean;
}

export class Trainer implements TrainerInterface {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isAdmin: boolean;
  isActivated: boolean;

  constructor(
    uuid: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    isAdmin: boolean,
    isActivated: boolean
  ) {
    this.uuid = uuid;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.isAdmin = isAdmin;
    this.isActivated = isActivated;
  }
}
