import { Typography } from "@mui/material";
import "../../../css/globalVariables.css";

const InfoCardTypography: React.FC<{
  text: string;
}> = ({ text }) => {
  return (
    <>
      <Typography
        sx={{
          color: "var(--grey)",
          fontWeight: "bold",
          fontSize: "var(--h2)",
          textAlign: "center",
        }}
      >
        {text}
      </Typography>
    </>
  );
};

export default InfoCardTypography;
