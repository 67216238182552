import { Dialog, Typography } from "@mui/material";
import camera from "../../../img/camera.png";
import arrow from "../../../img/arrowLeftActive.png";
import trash from "../../../img/trash.png";
import addPhoto from "../../../img/addPhoto.png";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Attachment } from "../../../models/attachment";
import {
  deleteConditionAttachment,
  deleteIncidentAttachment,
} from "../../../api/course-api";
import ImageRenderer from "./ImageRenderer";
import { useParams } from "react-router-dom";

const ImageInput: React.FC<{
  courseName: string;
  conditionPrefix?: string;
  incidentUUID?: string;
  attachments: Attachment[];
  onChangeAttachments: (_: Attachment[]) => void;
  isItemSaved: boolean;
}> = ({
  courseName,
  conditionPrefix,
  incidentUUID,
  attachments,
  onChangeAttachments,
  isItemSaved,
}) => {
  const { courseID } = useParams<{ courseID: string }>();

  const conditionMap: Record<string, any> = {
    hallCondition: { hallCondition: true },
    materialCondition: { materialCondition: true },
    trainerCondition: { trainerCondition: true },
  };

  const [selectedAttachment, setSelectedAttachment] =
    useState<Attachment | null>(null);
  const [attachmentDialogOpen, setAttachmentDialogOpen] =
    useState<boolean>(false);
  const [galleryButtonDisabled, setGalleryButtonDisabled] =
    useState<boolean>(false);

  /* eslint-disable */
  useEffect(() => {
    onChangeAttachments(attachments);
  }, [attachments]);

  /* eslint-enable */

  const addAttachment = (attachment: Attachment) => {
    if (attachments.length < 3) {
      onChangeAttachments([...attachments, attachment]);

      if (attachments.length === 2) {
        setGalleryButtonDisabled(true);
      }
    }
  };

  const handleGallerySelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const attachment: Attachment = Attachment.create(
        reader.result as string,
        file.name
      );
      addAttachment(attachment);
    };
    reader.readAsDataURL(file);
  };

  const deleteImage = async (deleteAttachment: Attachment) => {
    const deleteIndex = attachments.indexOf(deleteAttachment);
    if (conditionPrefix) {
      await deleteConditionAttachment(
        courseID,
        deleteAttachment.link,
        conditionMap[conditionPrefix]
      );
    } else {
      await deleteIncidentAttachment(
        courseID,
        incidentUUID,
        deleteAttachment.link
      );
    }

    const newAttachments = attachments.filter((_, i) => i !== deleteIndex);
    onChangeAttachments(newAttachments);
    if (newAttachments.length < 3) {
      setGalleryButtonDisabled(false);
    }
    if (newAttachments.length >= 1) {
      setSelectedAttachment(attachments[0]);
    }
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}
      >
        {attachments.length < 3 && !isItemSaved && (
          <>
            <label htmlFor={conditionPrefix ? conditionPrefix : incidentUUID}>
              <img
                src={camera}
                alt={"Kamera aktvieren"}
                style={{
                  width: "23px",
                  margin: "10px 10px 10px 0",
                  opacity: isItemSaved ? "0.5" : "",
                }}
              />
            </label>
            <input
              id={conditionPrefix ? conditionPrefix : incidentUUID}
              type="file"
              accept="image/*"
              onChange={handleGallerySelect}
              disabled={isItemSaved}
              style={{
                display: "none",
              }}
            ></input>
          </>
        )}
        {attachments.map((attachment, index) => {
          return (
            <ImageRenderer
              key={index}
              attachment={attachment}
              index={index}
              onClickFct={() => {
                setAttachmentDialogOpen(true);
                setSelectedAttachment(attachment);
              }}
            ></ImageRenderer>
          );
        })}
      </div>

      {selectedAttachment && (
        <Dialog fullScreen open={attachmentDialogOpen}>
          <img
            alt="selectedImg"
            src={
              selectedAttachment.link
                ? selectedAttachment.link
                : selectedAttachment.base64
            }
            style={{ maxWidth: "100%" }}
          />
          <button
            disabled={isItemSaved}
            aria-label={"Foto löschen"}
            onClick={async () => {
              setAttachmentDialogOpen(false);
              await deleteImage(selectedAttachment);
            }}
            style={{
              position: "absolute",
              top: "60px",
              left: "10px",
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "none",
            }}
          >
            <img src={trash} alt={"Foto löschen"} style={{ height: "25px" }} />
          </button>
          <button
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              fontWeight: "bold",
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "none",
              display: "flex",
              flexDirection: "row",
            }}
            onClick={() => setSelectedAttachment(null)}
          >
            <img
              src={arrow}
              alt="arrow"
              style={{ height: "20px", margin: "10px" }}
            />
            <Typography
              sx={{
                fontSize: "var(--h1)",
                fontWeight: "bold",
                color: "var(--orange)",
                margin: "auto",
              }}
            >
              {courseName}
            </Typography>
          </button>

          <Box
            sx={{
              position: "absolute",
              bottom: "20px",
              left: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
            }}
          >
            {attachments.length < 3 && (
              <Box>
                <label
                  htmlFor={conditionPrefix ? conditionPrefix : incidentUUID}
                >
                  <img
                    src={addPhoto}
                    alt={"Kamera aktvieren"}
                    style={{ width: "25px", margin: "10px" }}
                  />
                </label>
                <input
                  id={conditionPrefix ? conditionPrefix : incidentUUID}
                  type="file"
                  accept="image/*"
                  onChange={handleGallerySelect}
                  disabled={galleryButtonDisabled}
                  style={{
                    display: "none",
                  }}
                ></input>
              </Box>
            )}
            {attachments.map((attachment, index) => {
              return (
                <ImageRenderer
                  attachment={attachment}
                  index={index}
                  onClickFct={() => {
                    setAttachmentDialogOpen(true);
                    setSelectedAttachment(attachment);
                  }}
                ></ImageRenderer>
              );
            })}
          </Box>
        </Dialog>
      )}
    </div>
  );
};

export default ImageInput;
