const BUCKET_NAME = "sportfreunde06trainerappbucket";

export class Attachment {
  link?: string;
  base64?: string;
  filename?: string;

  constructor(link?: string, base64?: string, filename?: string) {
    if (link) {
      // eslint-disable-next-line react/no-is-mounted
      this.link = this.getLink(link);
    }
    if (base64) {
      this.base64 = base64;
      this.filename = filename;
    }
  }

  getLink(filename: string) {
    return `https://${BUCKET_NAME}.s3.eu-central-1.amazonaws.com/${filename}`;
  }

  static create(base64: string, filename: string): Attachment {
    return new Attachment(undefined, base64, filename);
  }
}
