import { Box, ThemeProvider, Typography } from "@mui/material";
import { customTheme } from "../../customTheme";
import logout from "../../../img/logout.png";
import classes from "../../../css/ProfileBtn.module.css";

const LogoutBtn: React.FC<{
  text: string;
  onClickHandler: () => void;
}> = ({ text, onClickHandler }) => {
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          style={{
            cursor: "pointer",
            textDecoration: "none",
            marginTop: "auto",
            marginBottom: "auto",
          }}
          sx={{ display: "flex", flexDirection: "row" }}
          onClick={onClickHandler}
        >
          <img alt="arrow" className={classes.arrow} src={logout} />
          <Typography sx={{ color: "var(--orange)" }}>{text}</Typography>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default LogoutBtn;
