import { getTrainerAxiosInstance } from "./api-config";

export async function sendEmail(
  sender: string,
  to: string,
  bcc: string[],
  subject: string,
  content: string
): Promise<{ success: boolean; message: string }> {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.post(`/email/send`, {
    sender,
    to,
    bcc,
    subject,
    content,
  });
  return response.data;
}
