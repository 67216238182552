import { useHistory, useParams } from "react-router-dom";
import InfoCard from "../../components/TrainerApp/InfoCard/InfoCard";
import { allRoutes } from "../../enums/all-routes";
import InfoCardYesNoBtns from "../../components/TrainerApp/InfoCard/InfoCardYesNoBtns";
import { Course } from "../../models/course";
import InfoCardTypography from "../../components/TrainerApp/InfoCard/InfoCardTypography";
import { cancelCourseDocu, getCourseEntity } from "../../api/course-api";
import { useContext, useEffect, useState } from "react";
import { TIMEOUT_MILLIS } from "../../constants/timeout-duration";
import { SnackBarContext } from "../../context/snackbar-context";
import GeneralDocuPage from "./GeneralDocuPage";

const CancelDocuPage: React.FC = () => {
  const history = useHistory();
  const snackBarCtx = useContext(SnackBarContext);

  const [course, setCourse] = useState<Course>({} as Course);

  const { courseID } = useParams<{ courseID: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    setIsLoading(true);
    getCourseEntity(courseID, snackBarCtx)
      .then((course: Course) => {
        setCourse(course);
        setTimeout(() => {
          setIsLoading(false);
          setShowContent(true);
        }, TIMEOUT_MILLIS);
      })
      .catch(() => {
        setIsLoading(false);
        setShowContent(true);
      });
  }, []);
  /* eslint-enable */

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            {showContent && (
              <InfoCard
                courseObj={course}
                backBtnText={course.courseName}
                backBtnPath={""}
                backBtnGoBack={true}
                content={
                  <InfoCardTypography
                    text={
                      "Möchtest du die Kursdokumentation wirklich abbrechen ? \n Alle Änderungen werden zurückgesetzt !"
                    }
                  ></InfoCardTypography>
                }
                btnChildren={
                  <InfoCardYesNoBtns
                    yesBtnText={"Ja"}
                    noBtnText={"Nein"}
                    yesBtnOnClick={async () => {
                      await cancelCourseDocu(course);
                      history.push(allRoutes.myCourses);
                    }}
                    noBtnOnClick={() => {
                      history.goBack();
                    }}
                  ></InfoCardYesNoBtns>
                }
              ></InfoCard>
            )}
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};

export default CancelDocuPage;
