import { Box, ThemeProvider, Typography } from "@mui/material";
import { customTheme } from "../../customTheme";
import phoneImg from "../../../img/phone.png";
import { useState } from "react";
import SnackbarMessage from "../../Snackbar/SnackbarMessage";
import "../../../css/globalVariables.css";

const InfoCardInfoContent: React.FC<{
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  participantSince: string;
}> = ({ firstName, lastName, phone, email, participantSince }) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box>
          <Box
            sx={{
              margin: "10px",
              border: "var(--border)",
              borderRadius: "var(--borderRadiusLarge)",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                margin: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "var(--h1)",
                  fontWeight: "bold",
                  marginBottom: "5px",
                  color: "var(--grey)",
                }}
              >
                {firstName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "var(--h2)",
                  lineHeight: "1.7",
                  color: "var(--grey)",
                }}
              >
                {lastName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "var(--h2)",
                  lineHeight: "1.7",
                  color: phone ? "var(--grey)" : "var(--orange)",
                }}
              >
                {phone ? phone : "Keine Telefonnummer vorhanden"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "var(--h2)",
                  lineHeight: "1.7",
                  color: "var(--grey)",
                }}
              >
                {email}
                <br />

                {participantSince && `Mitglied seit: ${participantSince}`}
              </Typography>
            </Box>
            <button
              onClick={() => {
                if (phone) {
                  const formattedPhoneNumber = phone.replace(/\s+/g, "");
                  window.location.href = "tel:" + formattedPhoneNumber;
                } else {
                  setOpenSnackBar(!openSnackBar);
                }
              }}
              style={{
                backgroundColor: "var(--green)",
                margin: "10px",
                height: "60px",
                width: "60px",
                padding: "5px",
                boxShadow: "none",
                border: "none",
                borderRadius: "var(--borderRadiusLarge)",
              }}
            >
              <img
                src={phoneImg}
                alt="phone"
                style={{
                  height: "20px",
                  margin: "auto",
                }}
              />
            </button>
          </Box>
        </Box>
        <SnackbarMessage
          open={openSnackBar}
          text={"Keine Telefonnummer vorhanden"}
          onCloseFct={() => {
            setOpenSnackBar(false);
          }}
        />
      </ThemeProvider>
    </>
  );
};

export default InfoCardInfoContent;
