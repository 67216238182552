import { Box, Grid, ThemeProvider } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { customTheme } from "../../customTheme";
import "../../../css/globalVariables.css";

const NavButtons: React.FC<{
  leftPathname?: string;
  leftText: string;
  leftBtnOnClick?: () => void;
  leftBtnDisabled?: boolean;
  rightPathname?: string;
  rightText: string;
  rightBtnOnClick?: () => void;
  rightBtnDisabled?: boolean;
}> = ({
  leftPathname,
  leftText,
  leftBtnOnClick,
  leftBtnDisabled,
  rightPathname,
  rightText,
  rightBtnOnClick,
  rightBtnDisabled,
}) => {
  const history = useHistory();
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box sx={{ marginTop: 10 }} />
        <Grid
          container
          justifyContent="center"
          sx={{
            position: "fixed",
            bottom: 15,
            left: 0,
            right: 0,
            backgroundColor: "white",
            boxShadow: "20px 20px white",
            zIndex: "999",
          }}
        >
          <div
            data-testid="left-nav-div"
            style={{
              width: "calc(50% - 20px)",
              borderRadius:
                "var(--borderRadiusLarge) 0 0 var(--borderRadiusLarge)",
              display: "flex",
              justifyContent: "center",
              textDecoration: "none",
              backgroundColor: "var(--orange)",
              opacity: leftBtnDisabled ? "0.5" : "",
              border: "1px solid white",
              boxShadow: "0 -15px white",
            }}
          >
            <button
              data-testid="left-nav-btn"
              onClick={() => {
                if (leftBtnDisabled) {
                  return;
                }
                if (leftBtnOnClick) {
                  leftBtnOnClick();
                }
                if (leftPathname && leftPathname !== "") {
                  history.push(leftPathname);
                }
              }}
              style={{
                width: "100%",
                margin: "3px",
                height: "35px",
                color: "white",
                fontWeight: "bold",
                fontSize: "var(--h4)",
                boxShadow: "none",
                border: "none",
                borderRadius: "var(--borderRadiusLarge)",
                backgroundColor: "transparent",
              }}
            >
              {leftText}
            </button>
          </div>
          <div
            data-testid="right-nav-div"
            style={{
              width: "calc(50% - 20px)",
              borderRadius:
                "0 var(--borderRadiusLarge) var(--borderRadiusLarge) 0",
              display: "flex",
              justifyContent: "center",
              textDecoration: "none",
              backgroundColor: "var(--orange)",
              opacity: rightBtnDisabled ? "0.5" : "",
              border: "1px solid white",
              boxShadow: "0 -15px white",
            }}
          >
            <button
              data-testid="right-nav-btn"
              onClick={() => {
                if (rightBtnDisabled) {
                  return;
                }
                if (rightBtnOnClick) {
                  rightBtnOnClick();
                }
                if (rightPathname && rightPathname !== "") {
                  history.push(rightPathname);
                }
              }}
              style={{
                width: "100%",
                margin: "3px",
                height: "35px",
                color: "white",
                fontWeight: "bold",
                fontSize: "var(--h4)",
                boxShadow: "none",
                border: "none",
                borderRadius: "var(--borderRadiusLarge)",
                backgroundColor: "transparent",
              }}
            >
              {rightText}
            </button>
          </div>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default NavButtons;
