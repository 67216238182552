import { HttpStatusCode } from "axios";
import { deleteCourse } from "../api/course-api";
import { Course } from "../models/course";
import { localStringToDate } from "./DateFormatHelper";
import { SnackBarContextObj } from "../context/snackbar-context";

export const mergeRecords = (
  firstRecord: Record<string, any[]>,
  secondRecord: Record<string, any[]>
): Record<string, any[]> => {
  const mergedRecord: Record<string, any[]> = {};

  for (const date in firstRecord) {
    if (firstRecord.hasOwnProperty(date)) {
      mergedRecord[date] = firstRecord[date].concat(secondRecord[date] || []);
    }
  }

  for (const date in secondRecord) {
    if (
      secondRecord.hasOwnProperty(date) &&
      !mergedRecord.hasOwnProperty(date)
    ) {
      mergedRecord[date] = secondRecord[date];
    }
  }

  const sortedMergedRecord: Record<string, any[]> = Object.fromEntries(
    Object.entries(mergedRecord).sort(([dateA], [dateB]) => {
      const dateAComponents = dateA.split(".").map(Number);
      const dateBComponents = dateB.split(".").map(Number);

      // Compare dates
      for (let i = 0; i < 3; i++) {
        if (dateAComponents[i] !== dateBComponents[i]) {
          return dateAComponents[i] - dateBComponents[i];
        }
      }
      return 0;
    })
  );

  return sortedMergedRecord;
};

export function groupAndSortCourses(
  courses: Course[],
  ascending = true
): Record<string, Course[]> {
  const courseRecord: Record<string, Course[]> = {};
  const sortedRecord: Record<string, Course[]> = {};

  // Group
  courses.forEach((course) => {
    const date: string = course.startDate.split(",")[0].trim();
    if (courseRecord[date]) {
      courseRecord[date].push(course);
    } else {
      courseRecord[date] = [course];
    }
  });

  // Sort
  Object.entries(courseRecord).map(([date, items]) => {
    return (courseRecord[date] = items.sort((a, b) => {
      return (
        localStringToDate(a.startDate).getTime() -
        localStringToDate(b.startDate).getTime()
      );
    }));
  });

  const customDateComparator = (dateStr1: string, dateStr2: string): number => {
    const [day1, month1, year1] = dateStr1.split(".");
    const [day2, month2, year2] = dateStr2.split(".");

    // Convert the date strings to Date objects
    const date1 = new Date(Number(year1), Number(month1) - 1, Number(day1));
    const date2 = new Date(Number(year2), Number(month2) - 1, Number(day2));

    if (ascending) {
      return date1.getTime() - date2.getTime();
    }
    // Compare the dates
    return date2.getTime() - date1.getTime();
  };

  // Sort Record Keys
  const sortedKeys = Object.keys(courseRecord).sort(customDateComparator);

  for (const key of sortedKeys) {
    sortedRecord[key] = courseRecord[key];
  }
  return sortedRecord;
}

export const adminDeleteCourse = async (
  idOfCourseToDelete: string,
  deleteDialogSetter: (_: React.SetStateAction<boolean>) => void,
  deleteCourseHandler: (idOfCourse: string) => void,
  snackBarCtx: SnackBarContextObj
) => {
  const response = await deleteCourse(idOfCourseToDelete);
  if (response.status === HttpStatusCode.Ok) {
    deleteDialogSetter(false);
    deleteCourseHandler(idOfCourseToDelete);
  } else {
    snackBarCtx.openSnackbar(
      `${response.status} | Ein Fehler ist aufgetreten bitte versuche es erneut...`
    );
  }
};
