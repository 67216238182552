import { Grid, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ImageInput from "./ImageInput";
import ModeIcon from "@mui/icons-material/Mode";
import "../../../css/globalVariables.css";
import { Attachment } from "../../../models/attachment";

const TextImageInput: React.FC<{
  courseName: string;
  onFocusHandler: () => void;
  onBlurHandler: () => void;
  textAreaOnChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder: string;
  textAreaDefaultValue: string;
  textAreaError: boolean;
  saveBtnHandler: React.MouseEventHandler<HTMLButtonElement>;
  saveBtnError: boolean;
  isItemSaved: boolean;
  editBtnHandler: React.MouseEventHandler<HTMLButtonElement>;
  attachments: Attachment[];
  onChangeAttachments: (_: Attachment[]) => void;
  conditionPrefix?: string;
  incidentUUID?: string;
}> = ({
  courseName,
  onFocusHandler,
  onBlurHandler,
  textAreaOnChange,
  placeholder,
  textAreaDefaultValue,
  textAreaError,
  saveBtnHandler,
  saveBtnError,
  isItemSaved,
  editBtnHandler,
  conditionPrefix,
  incidentUUID,
  attachments,
  onChangeAttachments,
}) => {
  return (
    <>
      <TextField
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        onChange={textAreaOnChange}
        value={textAreaDefaultValue}
        placeholder={placeholder}
        error={textAreaError}
        multiline
        sx={{ m: 2, width: "90%", hyphens: "auto" }}
        disabled={isItemSaved}
      ></TextField>
      <Grid container justifyContent={"space-between"}>
        {conditionPrefix !== "trainerCondition" ? (
          <ImageInput
            courseName={courseName}
            onChangeAttachments={onChangeAttachments}
            conditionPrefix={conditionPrefix}
            incidentUUID={incidentUUID}
            attachments={attachments}
            isItemSaved={isItemSaved}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "20px",
            }}
          ></div>
        )}

        {isItemSaved ? (
          <button
            onClick={editBtnHandler}
            style={{
              boxShadow: "none",
              border: "none",
              backgroundColor: "transparent",
              padding: "10px 20px 10px 0",
              color: "var(--orange)",
            }}
          >
            <ModeIcon></ModeIcon>
          </button>
        ) : (
          <button
            onClick={saveBtnHandler}
            style={{
              color: saveBtnError ? "var(--red)" : "var(--orange)",
              boxShadow: "none",
              border: "none",
              backgroundColor: "transparent",
              padding: "10px 20px 10px 0",
            }}
          >
            <SaveIcon
              sx={{ opacity: textAreaDefaultValue ? "" : "0.5" }}
            ></SaveIcon>
          </button>
        )}
      </Grid>
    </>
  );
};

export default TextImageInput;
