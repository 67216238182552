import { Dialog } from "@mui/material";
import { Course } from "../../../models/course";
import InfoCard from "../InfoCard/InfoCard";
import InfoCardInfoContent from "../InfoCard/InfoCardInfoContent";
import { Participant } from "../../../models/participant";
import { useState } from "react";
import SnackbarMessage from "../../Snackbar/SnackbarMessage";
import InfoCardSingleBtn from "../InfoCard/InfoCardSingleBtn";

const AttendeeDetailDialog: React.FC<{
  courseObj: Course;
  participantObj: Participant;
  isOpen: boolean;
  closeDialog: () => void;
  backBtnText: string;
  backBtnPath?: string;
}> = ({
  courseObj,
  participantObj,
  isOpen,
  closeDialog,
  backBtnText,
  backBtnPath,
}) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);

  return (
    <Dialog fullScreen open={isOpen} keepMounted>
      <InfoCard
        courseObj={courseObj}
        backBtnText={backBtnText}
        backBtnPath={backBtnPath}
        backBtnOnClick={closeDialog}
        content={
          <InfoCardInfoContent
            firstName={participantObj.firstName}
            lastName={participantObj.lastName}
            email={participantObj.email}
            phone={participantObj.phone}
            participantSince={participantObj.membershipStart}
          ></InfoCardInfoContent>
        }
        btnChildren={
          <InfoCardSingleBtn
            btnText={"Zurück"}
            btnOnClick={closeDialog}
          ></InfoCardSingleBtn>
        }
      ></InfoCard>
      <SnackbarMessage
        open={openSnackBar}
        text={"Keine Telefonnummer vorhanden"}
        onCloseFct={() => {
          setOpenSnackBar(false);
        }}
      />
    </Dialog>
  );
};

export default AttendeeDetailDialog;
