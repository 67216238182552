import {
  Dialog,
  ListItem,
  ListItemButton,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { allRoutes, getPathWithCourseID } from "../../../enums/all-routes";
import { Course } from "../../../models/course";
import { CourseStates } from "../../../models/course";
import { customTheme } from "../../customTheme";
import { getCourseEntity } from "../../../api/course-api";
import rectangle from "../../../img/rectangle.png";
import mail from "../../../img/mail.png";
import mailSVG from "../../../img/mailSVG.svg";
import penSVG from "../../../img/penSVG.svg";
import "../../../css/globalVariables.css";
import InfoCard from "../InfoCard/InfoCard";
import InfoCardSingleBtn from "../InfoCard/InfoCardSingleBtn";
import InfoCardTypography from "../InfoCard/InfoCardTypography";
import { getTimeFromDates } from "../../../util/DateFormatHelper";
import CourseItemBtn from "./CourseItemBtn";
import { SnackBarContext } from "../../../context/snackbar-context";
import { localStorageKeys } from "../../../constants/storage-keys";

const CourseItem: React.FC<{
  course: Course;
  startLoading: () => void;
  stopLoading: () => void;
}> = ({ course, startLoading, stopLoading }) => {
  const history = useHistory();
  const snackBarCtx = useContext(SnackBarContext);
  const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);

  const courseIsLateDocu = course.isLateDocu;
  const isCourseReadOnly = course.state === CourseStates.READ_ONLY;
  const isCourseReadyToEdit =
    course.state === CourseStates.IN_EDIT ||
    course.state === CourseStates.READY_FOR_CHECK_IN;

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogTextContent, setDialogTextContent] = useState<string>("");

  const [isSwiped, setIsSwiped] = useState(false);
  const [startX, setStartX] = useState<number | null>(null);
  const [currentX, setCurrentX] = useState<number | null>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  const redirectRecord: Record<string, string> = {
    [CourseStates.READY_FOR_CHECK_IN]: getPathWithCourseID(
      course.courseID,
      allRoutes.checkIn
    ),
    [CourseStates.IN_EDIT]: getPathWithCourseID(
      course.courseID,
      allRoutes.condition
    ),
  };

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Dialog fullScreen open={showDialog} keepMounted>
          <InfoCard
            courseObj={course}
            backBtnPath={allRoutes.myCourses}
            backBtnText={"Meine Kurse"}
            backBtnOnClick={() => {
              setShowDialog(false);
            }}
            content={
              <InfoCardTypography text={dialogTextContent}></InfoCardTypography>
            }
            btnChildren={
              <InfoCardSingleBtn
                btnText={"Zurück"}
                btnOnClick={() => {
                  setShowDialog(false);
                }}
              ></InfoCardSingleBtn>
            }
          ></InfoCard>
        </Dialog>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <button
            onClick={() => {
              history.push(
                getPathWithCourseID(course.courseID, allRoutes.notifyAttendees)
              );
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "110px",
              height: "100%",
              backgroundColor: "var(--orange)",
              position: "absolute",
              right: "0",
              top: "0",
              borderRadius: "0",
              boxShadow: "none",
              border: "none",
            }}
          >
            <img
              src={mail}
              alt="Info"
              style={{
                height: "25px",
                marginLeft: "auto",
                marginRight: "10px",
              }}
            />
          </button>
          <div
            onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => {
              const touch = e.touches[0];
              setStartX(touch.clientX);
              setCurrentX(touch.clientX);
            }}
            onTouchMove={(e: React.TouchEvent<HTMLDivElement>) => {
              if (startX === null) return;
              const touch = e.touches[0];
              setCurrentX(touch.clientX);
            }}
            onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => {
              if (
                currentX !== null &&
                startX !== null &&
                currentX < startX &&
                startX - currentX > 50
              ) {
                setIsSwiped(true);
              } else {
                setIsSwiped(false);
              }
              setStartX(null);
              setCurrentX(null);
            }}
            onTouchCancel={() => {
              setStartX(null);
              setCurrentX(null);
            }}
            ref={boxRef}
            style={{
              width: "1000px",
              transition: "transform 0.3s ease-in-out",
              transform: `translateX(${isSwiped ? -70 : 0}px)`,
              backgroundColor: "white",
              borderRadius: "var(--borderRadiusLarge)",
              zIndex: "50",
            }}
          >
            <ListItem
              onClick={async () => {
                startLoading();
                const curCourse = await getCourseEntity(
                  course.courseID,
                  snackBarCtx
                );
                const curCourseState: CourseStates = curCourse.state;
                if (curCourseState === CourseStates.UPCOMING) {
                  setShowDialog(true);
                  setDialogTextContent(
                    "Dieser Kurs hat noch nicht gestartet. Du kannst die Kursdokumentation 30 min vor Beginn des Kurses starten."
                  );
                }
                if (curCourseState === CourseStates.READ_ONLY) {
                  setShowDialog(true);
                  setDialogTextContent(
                    "Dieser Kurs wurde nicht rechtzeitig dokumentiert und ist nun nicht bearbeitbar. Bitte wende dich an einen Administrator."
                  );
                }

                if (
                  curCourse.documentingTrainerUUID === "" ||
                  curCourse.documentingTrainerUUID === trainerUUID
                ) {
                  history.push(redirectRecord[curCourseState.toString()]);
                } else {
                  setShowDialog(true);
                  setDialogTextContent(
                    "Ein Trainer hat die Dokumentation bereits angefangen. Du darfst zu diesem Zeitpunkt diesen Kurs nicht dokumentieren."
                  );
                }

                stopLoading();
              }}
              sx={{ borderRadius: "0", backgroundColor: "white" }}
            >
              <ListItemButton
                disabled={!isCourseReadyToEdit}
                sx={{ padding: "0", borderRadius: "0" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        maxWidth: "65%",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          padding: "3px 0 3px 0",
                          color: isCourseReadyToEdit
                            ? "var(--orange)"
                            : "var(--grey)",
                        }}
                      >
                        {course.courseName}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "var(--h3)", color: "var(--grey)" }}
                      >
                        {course.location}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginTop: "auto",
                        marginRight: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        {course.message && (
                          <CourseItemBtn
                            onClickFct={() => {
                              history.push(
                                getPathWithCourseID(
                                  course.courseID,
                                  allRoutes.notification
                                )
                              );
                            }}
                            backgroundColor={"var(--orange)"}
                            btnIcon={mailSVG}
                          ></CourseItemBtn>
                        )}
                        {isCourseReadOnly && (
                          <CourseItemBtn
                            onClickFct={() => {}}
                            backgroundColor="var(--red)"
                            btnIcon={penSVG}
                          ></CourseItemBtn>
                        )}
                        {courseIsLateDocu && (
                          <CourseItemBtn
                            onClickFct={() => {}}
                            backgroundColor="var(--orange)"
                            btnIcon={penSVG}
                          ></CourseItemBtn>
                        )}
                      </div>
                      <Typography
                        sx={{
                          fontSize: "var(--h3)",
                          color: "var(--grey)",
                          textAlign: "right",
                          paddingRight: "5px",
                        }}
                      >
                        {getTimeFromDates(course.startDate, course.endDate)}
                      </Typography>
                    </Box>
                  </Box>
                  <img
                    src={rectangle}
                    alt=""
                    style={{
                      height: "35px",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  />
                </Box>
              </ListItemButton>
            </ListItem>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};
export default CourseItem;
