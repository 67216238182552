import { List, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import { Course } from "../../../models/course";
import { customTheme } from "../../customTheme";
import "../../../css/globalVariables.css";
import ArchiveCourseItem from "./ArchiveCourseItem";

const ArchiveCourseDay: React.FC<{
  date: string;
  courses: Course[];
}> = ({ date, courses }) => {
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Typography
          sx={{
            fontSize: "var(--h4)",
            fontWeight: "bold",
            padding: "15px",
            color: "var(--grey)",
          }}
        >
          {date}
        </Typography>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            border: "var(--border)",
            borderRadius: "var(--borderRadiusLarge)",
            padding: "0",
            overflow: "hidden",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {courses.map((course) => (
            <React.Fragment key={course.id}>
              <ArchiveCourseItem
                key={course.id}
                course={course}
              ></ArchiveCourseItem>

              {course.id !== courses.at(-1).id && (
                <hr
                  style={{
                    margin: "1px",
                    border: "none",
                    backgroundColor: "var(--borderColor)",
                    height: "1px",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </List>
      </ThemeProvider>
    </>
  );
};

export default ArchiveCourseDay;
