import { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import { localStorageKeys } from "../constants/storage-keys";
import { AuthContext } from "../context/auth-context";
import { allRoutes } from "../enums/all-routes";

const AuthPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  /* eslint-disable */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const adminAccessToken = params.get("adminAccessToken");
    const trainerAccessToken = params.get("trainerAccessToken");
    const trainerUUID = params.get("trainerUUID");
    const isAdmin: boolean = params.get("isAdmin") === "true";

    localStorage.setItem(localStorageKeys.adminAccessToken, adminAccessToken);
    localStorage.setItem(
      localStorageKeys.trainerAccessToken,
      trainerAccessToken
    );

    authCtx.setIsAdmin(isAdmin);
    localStorage.setItem(localStorageKeys.isAdmin, params.get("isAdmin"));
    localStorage.setItem(localStorageKeys.trainerUUID, trainerUUID);

    history.push(allRoutes.myCourses);
  }, []);
  /* eslint-enable */

  return (
    <>
      <LoadingScreen isLoading={true}></LoadingScreen>
    </>
  );
};

export default AuthPage;
