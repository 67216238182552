import { Backdrop, CircularProgress } from "@mui/material";

const LoadingScreen: React.FC<{
  isLoading: boolean;
}> = ({ isLoading }) => {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default LoadingScreen;
