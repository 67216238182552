import { ThemeProvider, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { allRoutes } from "../../enums/all-routes";
import { customTheme } from "../../components/customTheme";
import { Course } from "../../models/course";
import "../../css/globalVariables.css";
import { useHistory, useParams } from "react-router-dom";
import InfoCard from "../../components/TrainerApp/InfoCard/InfoCard";
import InfoCardSingleBtn from "../../components/TrainerApp/InfoCard/InfoCardSingleBtn";
import InfoCardTypography from "../../components/TrainerApp/InfoCard/InfoCardTypography";
import { getCourseEntity } from "../../api/course-api";
import { SnackBarContext } from "../../context/snackbar-context";
import GeneralDocuPage from "./GeneralDocuPage";

const NotificationPage: React.FC = () => {
  const history = useHistory();
  const snackBarCtx = useContext(SnackBarContext);
  const [courseObj, setCourseObj] = useState<Course>({} as Course);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { courseID } = useParams<{ courseID: string }>();

  /* eslint-disable */
  useEffect(() => {
    setIsLoading(true);
    getCourseEntity(courseID, snackBarCtx).then((c: Course) => {
      setCourseObj(c);
      setIsLoading(false);
    });
  }, [courseID]);
  /* eslint-enable */

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            <ThemeProvider theme={customTheme}>
              <InfoCard
                courseObj={courseObj}
                backBtnText={"Meine Kurse"}
                backBtnPath={allRoutes.myCourses}
                content={
                  <>
                    <InfoCardTypography
                      text={
                        "Es wurde eine Nachricht an alle Teilnehmenden gesendet"
                      }
                    />

                    <Typography
                      sx={{
                        margin: "10px",
                        padding: "15px",
                        border: "var(--border)",
                        borderRadius: "var(--borderRadiusLarge)",
                        color: "var(--grey)",
                        fontSize: "var(--p0)",
                      }}
                    >
                      {courseObj.message}
                    </Typography>
                  </>
                }
                btnChildren={
                  <InfoCardSingleBtn
                    btnText={"Zurück"}
                    btnOnClick={() => {
                      history.push(allRoutes.myCourses);
                    }}
                  ></InfoCardSingleBtn>
                }
              ></InfoCard>
            </ThemeProvider>
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};

export default NotificationPage;
