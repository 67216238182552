import { Attachment } from "../../../models/attachment";

const ImageRenderer: React.FC<{
  attachment: Attachment;
  index: number;
  onClickFct: () => void;
}> = ({ attachment, index, onClickFct }) => {
  return (
    <>
      <div
        key={index}
        style={{ margin: "auto 5px auto 0" }}
        onClick={onClickFct}
      >
        <img
          src={attachment.link ? attachment.link : attachment.base64}
          alt={`image_${index}`}
          width="30px"
          style={{
            border: "2px solid var(--orange)",
            borderRadius: "var(--borderRadiusSmall)",
          }}
        />
      </div>
    </>
  );
};

export default ImageRenderer;
