const ConditionBtn: React.FC<{
  onClickHandler: React.MouseEventHandler<HTMLButtonElement>;
  className: string;
  borderColor: string;
  style: React.CSSProperties;
  isExtended: boolean;
  children: React.ReactNode;
}> = ({
  onClickHandler,
  className,
  borderColor,
  style,
  isExtended,
  children,
}) => {
  return (
    <>
      <button
        onClick={onClickHandler}
        className={className}
        style={{
          borderTop: `1px solid ${borderColor}`,
          borderBottom: isExtended ? `1px solid ${borderColor}` : "none",
          ...style,
        }}
      >
        {children}
      </button>
    </>
  );
};

export default ConditionBtn;
