import "../../css/globalVariables.css";
import { ThemeProvider } from "@emotion/react";
import { customTheme } from "../../components/customTheme";
import { Box, Dialog, MenuItem, TextField } from "@mui/material";
import { Course, CourseStates } from "../../models/course";
import HeaderCourseDocu from "../../components/TrainerApp/Header/HeaderCourseDocu";
import { useContext, useEffect, useState } from "react";
import { sendEmail } from "../../api/email-api";
import {
  getCourseEntity,
  getParticipantsByCourseName,
  updateCourse,
} from "../../api/course-api";
import { allRoutes } from "../../enums/all-routes";
import { getDate } from "../../util/DateFormatHelper";
import { Trainer } from "../../models/trainer";
import InfoCard from "../../components/TrainerApp/InfoCard/InfoCard";
import InfoCardYesNoBtns from "../../components/TrainerApp/InfoCard/InfoCardYesNoBtns";
import InfoCardSingleBtn from "../../components/TrainerApp/InfoCard/InfoCardSingleBtn";
import { useHistory, useParams } from "react-router-dom";
import InfoCardTypography from "../../components/TrainerApp/InfoCard/InfoCardTypography";
import TextFieldWithChars from "../../components/TextFieldWithChars";
import { Participant } from "../../models/participant";
import { SnackBarContext } from "../../context/snackbar-context";
import GeneralDocuPage from "./GeneralDocuPage";
import { getTrainerEntity } from "../../api/trainer-api";
import { formatSnackBarErrMsg } from "../../util/SnackBarErrorMsgFormatter";
import { HttpStatusCode } from "axios";
import { localStorageKeys } from "../../constants/storage-keys";

const SelectTextfield: React.FC<{
  values: string[];
  label: string;
  onChange: (value: string) => void;
  error?: boolean;
}> = ({ values, label, onChange, error }) => {
  const [selectedValue, setSelectedValue] = useState("");

  /* eslint-disable */
  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue]);
  /* eslint-enable */

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
  };

  return (
    <TextField
      error={error}
      sx={{ width: "100%", marginTop: "20px", color: "grey" }}
      value={selectedValue}
      onChange={handleChange}
      select
      label={label}
    >
      {values.map((value, index) => (
        <MenuItem key={index} value={value}>
          {value}
        </MenuItem>
      ))}
    </TextField>
  );
};

const NotifyAttendeesPage: React.FC = () => {
  const { courseID } = useParams<{ courseID: string }>();

  const MIN_CHARS_TEXTFIELD = 20;
  const MAX_CHARS_TEXTFIELD = 400;

  const MAX_CHARS_SUBJECT = 40;

  const history = useHistory();

  const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);

  const snackBarCtx = useContext(SnackBarContext);

  const [course, setCourse] = useState<Course>({} as Course);
  const [trainer, setTrainer] = useState<Trainer>({} as Trainer);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [title, setTitle] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [subject, setSubject] = useState("");
  const [isSubjectValid, setIsSubjectValid] = useState(true);
  const [message, setMessage] = useState("");
  const [isMessageValid, setIsMessageValid] = useState(true);

  const [isInputValid, setIsInputValid] = useState(false);

  const [showQuestionDialog, setShowQuestionDialog] = useState(false);

  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [infoDialogText, setInfoDialogText] = useState("");

  const [isInputFocusOff, setIsInputFocusOff] = useState(true);

  /* eslint-disable */
  useEffect(() => {
    setIsLoading(true);
    getCourseEntity(courseID, snackBarCtx).then((c: Course) => {
      setCourse(c);
    });

    getTrainerEntity(trainerUUID).then(
      ({ status, trainer }: { status: number; trainer: Trainer }) => {
        if (status !== HttpStatusCode.Ok) {
          snackBarCtx.openSnackbar(
            formatSnackBarErrMsg(
              status,
              `Beim Laden des TrainerObjektes ist ein Fehler aufgetreten. Bitte lade die Seite neu.`
            )
          );
          return;
        }
        setTrainer(trainer);
        setIsLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    setIsInputValid(checkIsInputValid());
  }, [subject, title, message]);
  /* eslint-enable */

  const checkTitleValid = () => {
    return title.length !== 0;
  };

  const checkSubjectValid = () => {
    return subject.length !== 0;
  };

  const checkMsgValid = () => {
    return message.length >= MIN_CHARS_TEXTFIELD;
  };

  const checkIsInputValid = () => {
    return checkTitleValid() && checkSubjectValid() && checkMsgValid();
  };

  async function handleSendBtn() {
    const sender = `${trainer.firstName} ${trainer.lastName} - Sportfreunde 06 e.V`;

    const mailTitle = `${title} - ${course.courseName} ${getDate(
      course.startDate
    )}: ${subject}`;

    const mailText = `<p>${message}</p><p>-</p>
    <p>${trainer.firstName} ${trainer.lastName}</p>
      <p> Sportfreunde 06 e.V </p>
      <p> Spiekermannstraße 31 A </p>
      <p> 13189 Berlin </p>
      <hr/> <strong>Bitte nicht auf diese E-Mail antworten. Die Antworten werden nicht gelesen. </strong>`;

    const participantsMails = (
      await getParticipantsByCourseName(course.courseName)
    ).map((p: Participant) => p.email);

    const result = await sendEmail(
      sender,
      "mitglieder@sportfreunde-berlin.de",
      participantsMails,
      mailTitle,
      mailText
    );

    setInfoDialogText(
      result.success === true
        ? "Deine Nachricht wurde erfolgreich an alle Teilnehmenden gesendet"
        : `Deine Nachricht konnte nicht gesendet werden. ${result.message}`
    );

    let updateAttr: Record<string, any>;
    if (title === "Nachricht") {
      updateAttr = {
        message: message,
      };
    }
    if (title === "Absage") {
      updateAttr = {
        message: message,
        documentingTrainerUUID: trainer.uuid,
        state: CourseStates.CANCELED,
      };
    }
    await updateCourse(courseID, updateAttr);
    setShowQuestionDialog(false);
    setShowInfoDialog(true);
  }

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            <ThemeProvider theme={customTheme}>
              {isInputFocusOff && (
                <HeaderCourseDocu
                  course={course}
                  showTopStepper={false}
                  backBtnText={"Meine Kurse"}
                  backBtnPath={allRoutes.myCourses}
                ></HeaderCourseDocu>
              )}
              <Box sx={{ margin: "100px 20px 80px 20px" }}>
                <SelectTextfield
                  error={!isTitleValid}
                  values={["Nachricht", "Absage"]}
                  label={"Anliegen auswählen"}
                  onChange={(value: string) => {
                    setTitle(value);
                  }}
                />

                <TextField
                  error={!isSubjectValid}
                  multiline
                  onFocus={() => setIsInputFocusOff(false)}
                  onBlur={() => setIsInputFocusOff(true)}
                  rows={1}
                  sx={{ width: "100%", marginTop: "20px" }}
                  placeholder={"Betreff"}
                  variant="outlined"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                  inputProps={{ maxLength: MAX_CHARS_SUBJECT }}
                />

                <TextFieldWithChars
                  placeholder={
                    "Schreibe eine Nachricht an alle Teilnehmenden des Kurses"
                  }
                  focusHandler={() => setIsInputFocusOff(false)}
                  blurHandler={() => setIsInputFocusOff(true)}
                  minChars={MIN_CHARS_TEXTFIELD}
                  maxChars={MAX_CHARS_TEXTFIELD}
                  rows={11}
                  onChange={(text: string) => {
                    setMessage(text);
                  }}
                  error={!isMessageValid}
                />

                <button
                  style={{
                    backgroundColor: isInputValid
                      ? "var(--orange)"
                      : "var(--lightOrange)",
                    color: "white",
                    width: "100%",
                    marginTop: "20px",
                    boxShadow: "none",
                    height: "50px",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: "var(--borderRadiusLarge)",
                  }}
                  onClick={() => {
                    if (isInputValid) {
                      setShowQuestionDialog(true);
                    } else {
                      setIsTitleValid(checkTitleValid());
                      setIsSubjectValid(checkSubjectValid());
                      setIsMessageValid(checkMsgValid());
                    }
                  }}
                >
                  {"Nachricht"}
                </button>
              </Box>

              <Dialog fullScreen open={showQuestionDialog} keepMounted>
                <InfoCard
                  courseObj={course}
                  backBtnText={course.courseName}
                  backBtnPath={allRoutes.myCourses}
                  content={
                    <InfoCardTypography
                      text={
                        "Möchtest du diese Nachricht an alle Teilnehmenden des Kurses senden?"
                      }
                    />
                  }
                  btnChildren={
                    <InfoCardYesNoBtns
                      yesBtnText={"Ja"}
                      noBtnText={"Nein"}
                      yesBtnOnClick={async () => await handleSendBtn()}
                      noBtnOnClick={() => setShowQuestionDialog(false)}
                    ></InfoCardYesNoBtns>
                  }
                ></InfoCard>
              </Dialog>

              <Dialog fullScreen open={showInfoDialog} keepMounted>
                <InfoCard
                  courseObj={course}
                  backBtnText={course.courseName}
                  backBtnPath={allRoutes.myCourses}
                  content={<InfoCardTypography text={infoDialogText} />}
                  btnChildren={
                    <InfoCardSingleBtn
                      btnText={"Zurück"}
                      btnOnClick={() => {
                        setShowInfoDialog(false);
                        history.push(allRoutes.myCourses);
                      }}
                    ></InfoCardSingleBtn>
                  }
                ></InfoCard>
              </Dialog>
            </ThemeProvider>
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};
export default NotifyAttendeesPage;
