import {
  Box,
  ThemeProvider,
  Typography,
  TextField,
  Dialog,
} from "@mui/material";
import "../../../css/globalVariables.css";
import { customTheme } from "../../customTheme";
import ImageRenderer from "../ImageIO/ImageRenderer";
import { Attachment } from "../../../models/attachment";
import { useState } from "react";

const ArchiveIncidentItem: React.FC<{
  title: string;
  description: string;
  attachments?: Attachment[];
}> = ({ title, description, attachments }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image: any) => {
    setSelectedImage(image);
    setOpen(true);
  };

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          sx={{
            border: "1px solid var(--orange)",
            borderRadius: "var(--borderRadiusLarge)",
            marginTop: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "var(--h4)",
              margin: "10px",
              textTransform: "uppercase",
              color: "var(--orange)",
            }}
          >
            {title}
          </Typography>

          <Box sx={{ width: "100%" }}>
            <TextField
              value={description}
              multiline
              sx={{
                m: 2,
                width: "90%",
                border: "1px solid var(--orange)",
                borderRadius: "var(--borderRadiusLarge)",
              }}
            ></TextField>

            <Box
              sx={{
                margin: "0 10px 5px 10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {attachments &&
                attachments.map((attachment, index) => {
                  return (
                    <ImageRenderer
                      key={index}
                      attachment={attachment}
                      index={index}
                      onClickFct={() => handleImageClick(attachment)}
                    ></ImageRenderer>
                  );
                })}
              <Dialog
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
              >
                <img
                  src={selectedImage ? selectedImage.link : ""}
                  alt="large"
                  style={{ maxWidth: "100%" }}
                />
              </Dialog>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default ArchiveIncidentItem;
