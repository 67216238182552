import "../../css/globalVariables.css";
import { Box, ThemeProvider, Typography } from "@mui/material";
import BottomNavigationBar from "../../components/TrainerApp/Navigation/BottomNavigationBar";
import { customTheme } from "../../components/customTheme";
import contact from "../../img/kontakt.png";
import web from "../../img/360.png";
import arrow from "../../img/arrow.png";
import info from "../../img/info.png";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import LogoutBtn from "../../components/TrainerApp/Profile/LogoutBtn";
import { allRoutes } from "../../enums/all-routes";
import { useHistory } from "react-router-dom";
import { Trainer } from "../../models/trainer";
import ProfileCard from "../../components/TrainerApp/Profile/ProfileCard";
import ProfileBtn from "../../components/TrainerApp/Profile/ProfileBtn";
import {
  SPORTFREUNDE_BEITRAGSORDNUNG_URL,
  SPORTFREUNDE_SATZUNG_URL,
} from "../../constants/weblinks";
import { logoutUser } from "../../api/auth-api";
import { addTestCourse } from "../../api/course-api";
import { getTrainerEntity } from "../../api/trainer-api";
import { HttpStatusCode } from "axios";
import { SnackBarContext } from "../../context/snackbar-context";
import GeneralDocuPage from "./GeneralDocuPage";
import { formatSnackBarErrMsg } from "../../util/SnackBarErrorMsgFormatter";
import { localStorageKeys } from "../../constants/storage-keys";
import { redirectToAdminApp, redirectToAuth } from "../../util/NavigationUtil";

const ProfilePage: React.FC = () => {
  const [trainer, setTrainer] = useState<Trainer>({} as Trainer);
  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const authContext = useContext(AuthContext);
  const snackBarCtx = useContext(SnackBarContext);
  const trainerUUID: string = localStorage.getItem(
    localStorageKeys.trainerUUID
  );
  const history = useHistory();

  /* eslint-disable */
  useEffect(() => {
    setIsLoading(true);
    getTrainerEntity(trainerUUID)
      .then((data) => {
        if (!data) {
          snackBarCtx.openSnackbar(
            formatSnackBarErrMsg(
              data.status,
              `Beim laden des Trainers ist ein Fehler aufgetreten. Bitte lade die Seite neu...`
            )
          );
          return;
        }
        if (data.status === HttpStatusCode.Ok) {
          setTrainer(data.trainer);
          authContext.setTrainer(data.trainer);
        } else {
          snackBarCtx.openSnackbar(
            formatSnackBarErrMsg(
              data.status,
              `Beim laden des Trainers ist ein Fehler aufgetreten. Bitte lade die Seite neu...`
            )
          );
        }
        setShowContent(true);
        setIsLoading(false);
      })
      .catch((_) => {
        setShowContent(true);
        setIsLoading(false);
        return;
      });
  }, []);
  /* eslint-enable */

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            {showContent && (
              <>
                <ThemeProvider theme={customTheme}>
                  <Box sx={{ margin: "20px", marginTop: "64px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "0 0 30px 0",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "var(--h0)",
                          color: "var(--grey)",
                        }}
                      >
                        {"Profil"}
                      </Typography>

                      <LogoutBtn
                        text={"Logout"}
                        onClickHandler={async () => {
                          await logoutUser();
                          redirectToAuth();
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <ProfileCard
                        trainerFirstName={trainer.firstName}
                        trainerLastName={trainer.lastName}
                        destination={allRoutes.profileDetails}
                      ></ProfileCard>

                      <Typography
                        sx={{
                          margin: "0 20px 0 20px",
                          fontWeight: "bold",
                          fontSize: "var(--p2)",
                          marginTop: "15px",
                          color: "var(--grey)",
                        }}
                      >
                        {"Allgemeines"}
                      </Typography>
                      <ProfileBtn
                        text={"Beitragsordnung"}
                        imgLeft={web}
                        imgRight={arrow}
                        onClickHandler={() =>
                          window.open(
                            SPORTFREUNDE_BEITRAGSORDNUNG_URL,
                            "_blank"
                          )
                        }
                      />
                      <ProfileBtn
                        text={"Satzung"}
                        imgLeft={info}
                        imgRight={arrow}
                        onClickHandler={() =>
                          window.open(SPORTFREUNDE_SATZUNG_URL, "_blank")
                        }
                      />

                      <Typography
                        sx={{
                          margin: "0 20px 0 20px",
                          fontWeight: "bold",
                          fontSize: "var(--p2)",
                          marginTop: "15px",
                          color: "var(--grey)",
                        }}
                      >
                        {"Kontakt"}
                      </Typography>
                      <ProfileBtn
                        text={"Kontaktformular"}
                        imgLeft={contact}
                        imgRight={arrow}
                        onClickHandler={() => {
                          history.push(allRoutes.contact);
                        }}
                      />
                      {trainer.isAdmin && (
                        <>
                          <ProfileBtn
                            text={"Testkurs anlegen"}
                            imgLeft={web}
                            imgRight={arrow}
                            onClickHandler={async () => {
                              await addTestCourse(trainer.uuid);
                            }}
                          />
                          <ProfileBtn
                            text={"Admin Portal"}
                            imgLeft={web}
                            imgRight={arrow}
                            onClickHandler={() => {
                              redirectToAdminApp();
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </Box>
                </ThemeProvider>

                <BottomNavigationBar></BottomNavigationBar>
              </>
            )}
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};

export default ProfilePage;
