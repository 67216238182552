import { Box, ThemeProvider, Typography } from "@mui/material";
import { customTheme } from "../../customTheme";
import { NavLink } from "react-router-dom";
import arrow from "../../../img/arrow.png";
import logo from "../../../img/wusel_luftsprung.png";
import classes from "../../../css/ProfileCard.module.css";
import "../../../css/globalVariables.css";

const ProfileCard: React.FC<{
  trainerFirstName: string;
  trainerLastName: string;
  destination: string;
}> = ({ trainerFirstName, trainerLastName, destination }) => {
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <NavLink to={destination} className={classes.link}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "var(--border)",
              borderRadius: "var(--borderRadiusLarge)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img className={classes.img} alt="logo" src={logo} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "var(--h2)",
                    color: "var(--grey)",
                  }}
                >
                  {trainerFirstName}
                </Typography>
                <Typography
                  sx={{ fontSize: "var(--h4)", color: "var(--grey)" }}
                >
                  {trainerLastName}
                </Typography>
              </Box>
            </Box>

            <img className={classes.arrow} src={arrow} alt="arrow" />
          </Box>
        </NavLink>
      </ThemeProvider>
    </>
  );
};

export default ProfileCard;
