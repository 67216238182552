import { Box, ThemeProvider, Typography } from "@mui/material";
import { customTheme } from "../../customTheme";
import { Participant } from "../../../models/participant";
import info from "../../../img/info2.png";
import "../../../css/globalVariables.css";

const InfoCardMissingAttendeesContent: React.FC<{
  participants: Participant[];
  infoHandler: (_: string) => void;
}> = ({ participants, infoHandler }) => {
  return (
    <>
      <Typography
        sx={{
          fontSize: "var(--h2)",
          fontWeight: "bold",
          textAlign: "center",
          color: "var(--grey)",
          marginTop: "75px",
          paddingBottom: "5px",
        }}
      >
        {"Fehlende Teilnehmende"}
      </Typography>
      <Box
        sx={{
          height: "60vh",
          overflow: "scroll",
          width: "80vw",
          marginBottom: "30px",
        }}
      >
        <ThemeProvider theme={customTheme}>
          <Box>
            {participants.map((p: Participant) => (
              <Box
                key={p.id}
                sx={{
                  border: "var(--border)",
                  margin: "10px",
                  borderRadius: "var(--borderRadiusLarge)",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box margin={"auto 15px auto 15px"}>
                  <Typography
                    sx={{
                      fontSize: "var(--h1)",
                      fontWeight: "bold",
                      marginBottom: "8px",
                      color: "var(--grey)",
                    }}
                  >
                    {p.firstName}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "var(--h2)", color: "var(--grey)" }}
                  >
                    {p.lastName}
                  </Typography>
                </Box>
                <button
                  onClick={() => infoHandler(p.id)}
                  style={{
                    backgroundColor: "var(--green)",
                    margin: "10px",
                    height: "60px",
                    width: "60px",
                    padding: "5px",
                    boxShadow: "none",
                    border: "none",
                    borderRadius: "var(--borderRadiusLarge)",
                  }}
                >
                  <img
                    src={info}
                    alt="Info"
                    style={{
                      height: "20px",
                      margin: "auto",
                    }}
                  />
                </button>
              </Box>
            ))}
          </Box>
        </ThemeProvider>
      </Box>
    </>
  );
};

export default InfoCardMissingAttendeesContent;
