import { Trainer } from "../models/trainer";
import { getTrainerAxiosInstance } from "./api-config";

export async function checkTrainerPassword(
  trainerUUID: string,
  password: string
) {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.post(`/trainers/check-password`, {
    trainerUUID: trainerUUID,
    password: password,
  });
  return response.data;
}

export async function changeTrainerPassword(
  trainerUUID: string,
  password: string
) {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.post(`/trainers/change-password`, {
    trainerUUID: trainerUUID,
    password: password,
  });
  return response.data;
}

export async function activateTrainer(trainerUUID: string) {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.post(
    `/trainers/${trainerUUID}/activate-trainer`
  );
  return response.data;
}

export async function resetTrainerPassword(
  trainerEmail: string
): Promise<{ success: boolean; message: string }> {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.post(`/trainers/reset-password`, {
    trainerEmail: trainerEmail,
  });
  return response.data;
}

export async function getTrainerEntity(trainerUUID: string): Promise<{
  status: number;
  trainer: Trainer;
}> {
  if (trainerUUID === "" || !trainerUUID) {
    return;
  }
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(`/trainers/${trainerUUID}`);
  const trainerData = response.data;

  return {
    status: response.status,
    trainer: new Trainer(
      trainerData.uuid,
      trainerData.firstName,
      trainerData.lastName,
      trainerData.email,
      trainerData.phone,
      trainerData.isAdmin,
      trainerData.isActivated
    ),
  };
}
