import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import InfoCard from "../../components/TrainerApp/InfoCard/InfoCard";
import { allRoutes, getPathWithCourseID } from "../../enums/all-routes";
import InfoCardYesNoBtns from "../../components/TrainerApp/InfoCard/InfoCardYesNoBtns";
import { Course, CourseStates } from "../../models/course";
import { getCourseEntity, updateCourse } from "../../api/course-api";
import { TIMEOUT_MILLIS } from "../../constants/timeout-duration";
import { SnackBarContext } from "../../context/snackbar-context";
import GeneralDocuPage from "./GeneralDocuPage";
import { localStorageKeys } from "../../constants/storage-keys";
import { HttpStatusCode } from "axios";
import { formatSnackBarErrMsg } from "../../util/SnackBarErrorMsgFormatter";

const CheckInPage: React.FC = () => {
  const history = useHistory();
  const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);
  const snackBarCtx = useContext(SnackBarContext);
  const { courseID } = useParams<{ courseID: string }>();

  const [course, setCourse] = useState<Course>({} as Course);

  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    setIsLoading(true);
    getCourseEntity(courseID, snackBarCtx)
      .then((course: Course) => {
        setCourse(course);
        setTimeout(() => {
          setIsLoading(false);
          setShowContent(true);
        }, TIMEOUT_MILLIS);
      })
      .catch(() => {
        setIsLoading(false);
        setShowContent(true);
      });
  }, []);
  /* eslint-enable */

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            {showContent && (
              <InfoCard
                courseObj={course}
                backBtnText="Meine Kurse"
                backBtnPath={allRoutes.myCourses}
                btnChildren={
                  <InfoCardYesNoBtns
                    yesBtnText="Ja"
                    noBtnText="Nein"
                    yesBtnOnClick={async () => {
                      const response = await updateCourse(courseID, {
                        checkInDate: new Date(),
                        state: CourseStates.IN_EDIT,
                        documentingTrainerUUID: trainerUUID,
                      });
                      if (response.status !== HttpStatusCode.Ok) {
                        snackBarCtx.openSnackbar(
                          formatSnackBarErrMsg(
                            response.status,
                            "Ein Fehler ist aufgetreten. Bitte versuche es erneut oder lade die Seite neu..."
                          )
                        );
                        return;
                      }
                      history.push(
                        getPathWithCourseID(courseID, allRoutes.condition)
                      );
                    }}
                    noBtnOnClick={() => {
                      history.push(allRoutes.myCourses);
                    }}
                  ></InfoCardYesNoBtns>
                }
                textContent="Möchtest du die Kursdokumentation starten ?"
              ></InfoCard>
            )}
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};

export default CheckInPage;
