import CoursePage from "./pages/TrainerApp/Course.page";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ArchivePage from "./pages/TrainerApp/Archive.page";
import ProfilPage from "./pages/TrainerApp/Profile.page";
import ConditionPage from "./pages/TrainerApp/Condition.page";
import { allRoutes, routesWithCourseID } from "./enums/all-routes";
import AttendancePage from "./pages/TrainerApp/AttendancePage";
import CheckOutPage from "./pages/TrainerApp/CheckOut.page";
import PrivateRoute from "./pages/Routes/PrivateRoute";
import CheckInPage from "./pages/TrainerApp/CheckIn.page";
import IncidentPage from "./pages/TrainerApp/Incident.page";
import ProfileDetailPage from "./pages/TrainerApp/ProfileDetail.page";
import ContactPage from "./pages/TrainerApp/Contact.page";
import NotifyAttendeesPage from "./pages/TrainerApp/NotifyAttendeesPage";
import NotificationPage from "./pages/TrainerApp/Notification.page";
import CancelDocuPage from "./pages/TrainerApp/CancelDocu.page";
import AuthPage from "./pages/AuthPage";

function App() {
  return (
    <>
      <main>
        <BrowserRouter>
          <Switch>
            <Route component={AuthPage} path={allRoutes.auth}></Route>
            <PrivateRoute
              exact={true}
              component={CoursePage}
              path={allRoutes.default}
            ></PrivateRoute>
            <PrivateRoute component={ContactPage} path={allRoutes.contact} />
            <PrivateRoute
              component={ProfileDetailPage}
              path={allRoutes.profileDetails}
            />
            <PrivateRoute component={CoursePage} path={allRoutes.myCourses} />
            <PrivateRoute component={ProfilPage} path={allRoutes.profile} />
            <PrivateRoute component={ArchivePage} path={allRoutes.archive} />
            <PrivateRoute
              component={CheckInPage}
              path={routesWithCourseID.checkIn}
            />
            <PrivateRoute
              component={ConditionPage}
              path={routesWithCourseID.condition}
            />
            <PrivateRoute
              component={AttendancePage}
              path={routesWithCourseID.attendance}
            />
            <PrivateRoute
              component={IncidentPage}
              path={routesWithCourseID.incident}
            />
            <PrivateRoute
              component={CheckOutPage}
              path={routesWithCourseID.checkOut}
            />
            <PrivateRoute
              component={NotifyAttendeesPage}
              path={routesWithCourseID.notifyAttendees}
            />
            <PrivateRoute
              component={NotificationPage}
              path={routesWithCourseID.notification}
            />
            <PrivateRoute
              component={CancelDocuPage}
              path={routesWithCourseID.cancelDocu}
            ></PrivateRoute>
          </Switch>
        </BrowserRouter>
      </main>
    </>
  );
}

export default App;
