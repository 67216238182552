import { Box, ThemeProvider, Typography } from "@mui/material";
import { customTheme } from "../../customTheme";
import classes from "../../../css/ProfileBtn.module.css";
import "../../../css/globalVariables.css";

const ProfileBtn: React.FC<{
  text: string;
  imgLeft: string;
  imgRight: string;
  onClickHandler: () => void;
}> = ({ text, imgLeft, imgRight, onClickHandler }) => {
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <button
          className={classes.button}
          onClick={() => {
            onClickHandler();
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              className={classes.img}
              src={imgLeft}
              alt={`${imgLeft.toString()}`}
            />

            <Typography
              sx={{
                color: "var(--grey)",
                fontWeight: "bold",
                fontSize: "var(--h4)",
                marginTop: "auto",
                marginBottom: "auto",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "70%",
              }}
            >
              {text}
            </Typography>
          </Box>

          <img className={classes.arrow} src={imgRight} alt="arrow" />
        </button>
      </ThemeProvider>
    </>
  );
};

export default ProfileBtn;
