/* eslint-disable */
import React, { useState } from "react";

export type MessageType = "error" | "success";

export type SnackBarContextObj = {
  isOpen: boolean;
  text: string;
  messageType?: MessageType;
  openSnackbar: (text: string, type?: MessageType) => void;
  closeSnackbar: () => void;
};

export const SnackBarContext = React.createContext<SnackBarContextObj>({
  isOpen: false,
  text: "",
  messageType: "error",
  openSnackbar: (_: string) => {},
  closeSnackbar: () => {},
});

const SnackBarContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // add state isError, setIsError default true
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  const [messageType, setMessageType] = useState<MessageType>("error");

  const openSnackbar = (text: string, type: MessageType = "error") => {
    setText(text);
    setIsOpen(true);
    setMessageType(type);
  };

  const closeSnackbar = () => {
    setIsOpen(false);
  };

  const contextValue: SnackBarContextObj = {
    isOpen,
    text,
    messageType,
    openSnackbar,
    closeSnackbar,
  };

  return (
    <SnackBarContext.Provider value={contextValue}>
      {children}
    </SnackBarContext.Provider>
  );
};

export default SnackBarContextProvider;
