import { useContext } from "react";
import LoadingScreen from "../../components/LoadingScreen";
import { SnackBarContext } from "../../context/snackbar-context";
import SnackbarMessage from "../../components/Snackbar/SnackbarMessage";

const GeneralDocuPage: React.FC<{
  isLoading?: boolean;
  children: React.ReactNode;
}> = ({ isLoading, children }) => {
  const snackBarCtx = useContext(SnackBarContext);

  return (
    <>
      {isLoading && <LoadingScreen isLoading={isLoading}></LoadingScreen>}
      <SnackbarMessage
        open={snackBarCtx.isOpen}
        text={snackBarCtx.text}
        onCloseFct={snackBarCtx.closeSnackbar}
      ></SnackbarMessage>

      {children}
    </>
  );
};

export default GeneralDocuPage;
