import "../../css/globalVariables.css";
import { Avatar, Box, ThemeProvider, Typography } from "@mui/material";
import BottomNavigationBar from "../../components/TrainerApp/Navigation/BottomNavigationBar";
import { customTheme } from "../../components/customTheme";
import ButtonNavBack from "../../components/TrainerApp/Navigation/NavBackBtn";
import { allRoutes } from "../../enums/all-routes";
import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import { Trainer } from "../../models/trainer";
import ProfileTextfield from "../../components/TrainerApp/Profile/ProfileTextfield";
import ProfileBtn from "../../components/TrainerApp/Profile/ProfileBtn";
import key from "../../img/key.png";
import photoWusel from "../../img/wusel_luftsprung.png";
import arrow from "../../img/arrow.png";
import { redirectToChangePassword } from "../../util/NavigationUtil";

const ProfileDetailPage: React.FC = () => {
  const authContext = useContext(AuthContext);
  const trainerObj: Trainer = authContext.trainer;

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          sx={{
            margin: "20px 20px 80px 20px",
          }}
        >
          <ButtonNavBack
            text={"Profil"}
            destination={allRoutes.profile}
          ></ButtonNavBack>
          <Typography
            sx={{
              margin: "20px 20px 20px 0px",
              fontWeight: "bold",
              fontSize: "var(--h0)",
              color: "var(--grey)",
            }}
          >
            {"Profil"}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "calc(100vh - 210px)",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Avatar
                src={photoWusel}
                sx={{
                  height: "7vh",
                  width: "7vh",
                  borderRadius: "var(--borderRadiusLarge)",
                  backgroundColor: "var(--lightGrey)",
                  padding: "20px",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              />

              <ProfileTextfield
                textName={"Name"}
                textInfo={trainerObj.firstName}
              ></ProfileTextfield>

              <ProfileTextfield
                textName={"Nachname"}
                textInfo={trainerObj.lastName}
              ></ProfileTextfield>

              <ProfileTextfield
                textName={"Telefon"}
                textInfo={trainerObj.phone}
              ></ProfileTextfield>

              <ProfileTextfield
                textName={"E-Mail"}
                textInfo={trainerObj.email}
              ></ProfileTextfield>
            </Box>
            <ProfileBtn
              text={"Passwort ändern"}
              imgLeft={key}
              imgRight={arrow}
              onClickHandler={() => {
                redirectToChangePassword();
              }}
            />
          </Box>
        </Box>
      </ThemeProvider>

      <BottomNavigationBar></BottomNavigationBar>
    </>
  );
};

export default ProfileDetailPage;
