import { useContext, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { redirectToAuth } from "../../util/NavigationUtil";

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact?: boolean;
}> = ({ component, path, exact }) => {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    authContext.areTokensAndTrainerUuidValid().then((value: boolean) => {
      if (!value) {
        redirectToAuth();
      }
    });
  }, [history.location.pathname, authContext, history]);

  return <Route exact={exact} component={component} path={path} />;
};

export default PrivateRoute;
