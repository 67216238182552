export enum allRoutes {
  default = "/",
  auth = "/auth",
  myCourses = "/meine-kurse",
  archive = "/archiv",
  archiveItem = "/archivItem",
  profile = "/profil",
  checkIn = "/checkin-page",
  condition = "/zustand-page",
  attendance = "/anwesenheit-page",
  incident = "/vorkommnis-page",
  checkOut = "/checkout-page",
  contact = "/kontakt",
  profileDetails = "/profilDetail",
  notification = "/benachrichtigung",
  changePassword = "/passwort-aendern",
  notifyAttendees = "/notifyAttendees",
  cancelDocu = "/cancel-docu",
}

export const routesWithCourseID: Record<string, string> = {
  checkIn: `/:courseID${allRoutes.checkIn}`,
  condition: `/:courseID${allRoutes.condition}`,
  attendance: `/:courseID${allRoutes.attendance}`,
  incident: `/:courseID${allRoutes.incident}`,
  checkOut: `/:courseID${allRoutes.checkOut}`,
  notifyAttendees: `/:courseID${allRoutes.notifyAttendees}`,
  notification: `/:courseID${allRoutes.notification}`,
  archive: `/:courseID${allRoutes.archiveItem}`,
  cancelDocu: `/:courseID${allRoutes.cancelDocu}`,
};

export function getPathWithCourseID(courseID: string, path: allRoutes) {
  return `/${courseID}${path}`;
}
