import { AxiosInstance } from "axios";
import authAxios from "../auth/auth.interceptor.service";
import trainerAxios from "../auth/trainer.interceptor.service";

export function getAuthAxiosInstance(): AxiosInstance {
  return authAxios;
}

export function getTrainerAxiosInstance(): AxiosInstance {
  return trainerAxios;
}
