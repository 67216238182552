import "../../../css/globalVariables.css";

const InfoCardSingleBtn: React.FC<{
  btnText: string;
  btnOnClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ btnText, btnOnClick }) => {
  return (
    <>
      <button
        style={{
          width: "100%",
          color: "white",
          fontWeight: "bold",
          borderRadius: "0 0 var(--borderRadiusLarge) var(--borderRadiusLarge)",
          backgroundColor: "var(--orange)",
          height: "40px",
          boxShadow: "none",
          border: "none",
          padding: "10px",
        }}
        onClick={btnOnClick}
      >
        {btnText}
      </button>
    </>
  );
};

export default InfoCardSingleBtn;
