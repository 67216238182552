import { Box, Typography } from "@mui/material";
import trash from "../../../img/trashWhite.png";
import plus from "../../../img/plus.png";
import "../../../css/globalVariables.css";
import { MouseEventHandler } from "react";
import IncidentTypeBtn from "./IncidentTypeBtn";
import { IncidentTypes } from "../../../pages/TrainerApp/Incident.page";
import { Incident } from "../../../models/incident";

const AddIncidentBtn: React.FC<{
  courseID: string;
  showIncidentSelection: boolean;
  onClickTrashBtn: MouseEventHandler<HTMLButtonElement>;
  onClickPlusBtn: MouseEventHandler<HTMLButtonElement>;
  onClickIncidentTypeBtn: MouseEventHandler<HTMLButtonElement>;
  onCreateIncident: (_: Incident) => void;
}> = ({
  courseID,
  showIncidentSelection,
  onClickTrashBtn,
  onClickPlusBtn,
  onClickIncidentTypeBtn,
  onCreateIncident,
}) => {
  const createIncident = (
    incidentType: IncidentTypes,
    mouseEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newIncident: Incident = Incident.create(incidentType, courseID);
    onCreateIncident(newIncident);
    onClickIncidentTypeBtn(mouseEvent);
  };

  return (
    <>
      <Box
        sx={{
          border: "var(--border)",
          borderRadius: "var(--borderRadiusLarge)",
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              margin: "auto 15px auto 15px",
              fontSize: "var(--h4)",
              fontWeight: "bold",
              textTransform: "uppercase",
              color: "var(--grey)",
            }}
          >
            {"Vorkommnis hinzufügen"}
          </Typography>

          {showIncidentSelection ? (
            <button
              onClick={onClickTrashBtn}
              style={{
                backgroundColor: "var(--red)",
                borderRadius: "var(--borderRadiusMedium)",
                margin: "10px",
                border: "none",
                height: "50px",
                width: "50px",
              }}
            >
              <img style={{ height: "20px" }} src={trash} alt="" />
            </button>
          ) : (
            <button
              onClick={onClickPlusBtn}
              style={{
                backgroundColor: "var(--orange)",
                borderRadius: "var(--borderRadiusMedium)",
                margin: "10px",
                border: "none",
                height: "50px",
                width: "50px",
              }}
            >
              <img style={{ height: "15px" }} src={plus} alt="" />
            </button>
          )}
        </Box>

        {showIncidentSelection && (
          <>
            <IncidentTypeBtn
              incidentType={IncidentTypes.INJURY}
              onClickHandler={async (
                e: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                createIncident(IncidentTypes.INJURY, e);
              }}
            ></IncidentTypeBtn>
            <IncidentTypeBtn
              incidentType={IncidentTypes.DAMAGE}
              onClickHandler={(e) => {
                createIncident(IncidentTypes.DAMAGE, e);
              }}
            ></IncidentTypeBtn>
            <IncidentTypeBtn
              incidentType={IncidentTypes.OTHER}
              onClickHandler={(e) => {
                createIncident(IncidentTypes.OTHER, e);
              }}
            ></IncidentTypeBtn>
          </>
        )}
      </Box>
    </>
  );
};

export default AddIncidentBtn;
