import { getAuthAxiosInstance } from "./api-config";
import { localStorageKeys } from "../constants/storage-keys";
import { AUTH_API_URL } from "../config/config";

export async function verifyTrainerTokens(accessToken: string) {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.post(
    `${AUTH_API_URL}/auth/verify-trainer`,
    { accessToken },
    {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": AUTH_API_URL,
        "Access-Control-Allow-Credentials": true,
      },
    }
  );
  return response.data;
}

export async function verifyAdminTokens(accessToken: string) {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.post(
    `${AUTH_API_URL}/auth/verify-admin`,
    { accessToken },
    {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": AUTH_API_URL,
        "Access-Control-Allow-Credentials": true,
      },
    }
  );
  return response.data;
}

export async function refreshTrainerToken() {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.get(`/auth/refresh-trainer`, {
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": AUTH_API_URL,
      "Access-Control-Allow-Credentials": true,
    },
  });
  const accessToken = response.data.accessToken;
  localStorage.setItem(localStorageKeys.trainerAccessToken, accessToken);
  return response;
}

export async function refreshAdminToken() {
  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.get(`/auth/refresh-admin`, {
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": AUTH_API_URL,
      "Access-Control-Allow-Credentials": true,
    },
  });
  const accessToken = response.data.accessToken;
  localStorage.setItem(localStorageKeys.adminAccessToken, accessToken);
  return response;
}

export async function logoutUser() {
  const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);
  const data = {
    trainerUUID: trainerUUID,
  };
  localStorage.clear();

  const axiosInstance = getAuthAxiosInstance();
  const response = await axiosInstance.post(
    `/auth/logout`,
    {
      data: data,
    },
    {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": AUTH_API_URL,
        "Access-Control-Allow-Credentials": true,
      },
    }
  );
  return response;
}
