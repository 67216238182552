import { Box, Step, StepConnector, Stepper } from "@mui/material";
import "../../../css/globalVariables.css";

const CustomStepConnector: React.FC<{
  isActive: boolean;
}> = ({ isActive }) => {
  return (
    <StepConnector
      sx={{
        border: isActive ? "0.5px solid var(--grey)" : "none",
        width: "100%",
        background: isActive ? "var(--grey)" : "transparent",
        boxShadow: isActive ? "inset 3px 3px 3px var(--grey)" : "none",
        "& .MuiStepConnector-line": {
          borderTopWidth: "1px",
          borderColor: "var(--grey)",
        },
      }}
    />
  );
};

const TopStepper: React.FC<{
  activeStep: number;
}> = ({ activeStep }) => {
  return (
    <Stepper
      sx={{
        margin: "15px 0 0 0",
      }}
    >
      <Step key={1} sx={{ padding: "0" }}>
        <Box
          sx={{
            height: "10px",
            width: "10px",
            borderRadius: "50%",
            background: "var(--grey)",
            border: "2px solid rgba(96, 119, 131, 0.5)",
          }}
        />
      </Step>

      <CustomStepConnector isActive={activeStep > 1}></CustomStepConnector>

      <Step key={2} sx={{ padding: "0" }}>
        <Box
          sx={{
            height: "10px",
            width: "10px",
            borderRadius: "50%",
            border: "2px solid rgba(96, 119, 131, 0.5)",
            background: activeStep > 1 ? "var(--grey)" : "transparent",
          }}
        ></Box>
      </Step>

      <CustomStepConnector isActive={activeStep === 3}></CustomStepConnector>

      <Step key={3} sx={{ padding: "0" }}>
        <Box
          sx={{
            height: "10px",
            width: "10px",
            borderRadius: "50%",
            background: activeStep === 3 ? "var(--grey)" : "transparent",
            border: "2px solid rgba(96, 119, 131, 0.5)",
          }}
        ></Box>
      </Step>
    </Stepper>
  );
};

export default TopStepper;
