import { useContext, useEffect, useState } from "react";
import { Course } from "../../models/course";
import { ThemeProvider, Typography } from "@mui/material";
import BottomNavigationBar from "../../components/TrainerApp/Navigation/BottomNavigationBar";
import { Box } from "@mui/system";
import { customTheme } from "../../components/customTheme";
import { getTrainerCourses } from "../../api/course-api";
import CourseDay from "../../components/TrainerApp/MyCourses/CourseDay";
import "../../css/globalVariables.css";
import LoadingScreen from "../../components/LoadingScreen";
import InfoCardTypography from "../../components/TrainerApp/InfoCard/InfoCardTypography";
import SnackbarMessage from "../../components/Snackbar/SnackbarMessage";
import { SnackBarContext } from "../../context/snackbar-context";
import {
  DEFAULT_ERR_MSG,
  formatSnackBarErrMsg,
} from "../../util/SnackBarErrorMsgFormatter";
import GeneralDocuPage from "./GeneralDocuPage";
import { localStorageKeys } from "../../constants/storage-keys";

const CoursePage: React.FC = () => {
  const snackBarCtx = useContext(SnackBarContext);
  const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);

  const [errorMsg, setErrorMsg] = useState("");

  const [trainerCourses, setTrainerCourses] = useState<
    Record<string, Course[]>
  >({});
  const [isInitialLoadingDone, setIsInitialLoadingDone] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadCourses = async () => {
    setIsLoading(true);
    getTrainerCourses(trainerUUID)
      .then((data) => {
        if (data.status === 200) {
          if (Object.keys(data.courses).length === 0) {
            setErrorMsg(
              "Du hast in den kommenden Tagen keine bevorstehenden Schichten."
            );
            return;
          }
          setTrainerCourses(data.courses);
          setErrorMsg("");
        } else {
          snackBarCtx.openSnackbar(
            formatSnackBarErrMsg(data.status, DEFAULT_ERR_MSG)
          );
        }
      })
      .catch((_) => {
        snackBarCtx.openSnackbar(
          "Ein Fehler ist aufgetreten bitte prüfe deine Internetverbindung und lade die Seite neu."
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /* eslint-disable */
  useEffect(() => {
    loadCourses().then(() => {
      setIsInitialLoadingDone(true);
    });
  }, []);

  useEffect(() => {
    const fetchData = () => {
      if (isInitialLoadingDone) {
        if (!isLoading) {
          loadCourses();
        }
      }
    };

    // Fetch data initially
    fetchData();

    const intervalId = setInterval(fetchData, 15 * 1000);

    return () => clearInterval(intervalId);
  }, []);
  /* eslint-enable */

  return (
    <>
      <LoadingScreen isLoading={isLoading}></LoadingScreen>
      <SnackbarMessage
        open={snackBarCtx.isOpen}
        text={snackBarCtx.text}
        onCloseFct={snackBarCtx.closeSnackbar}
      ></SnackbarMessage>

      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            <ThemeProvider theme={customTheme}>
              <Box
                sx={{ margin: "20px", marginTop: "64px", marginBottom: "80px" }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "var(--h0)",
                    color: "var(--grey)",
                  }}
                >
                  {"Meine nächsten Kurse"}
                </Typography>

                {errorMsg !== "" && (
                  <Box sx={{ marginTop: "3rem" }}>
                    <InfoCardTypography text={errorMsg}></InfoCardTypography>
                  </Box>
                )}

                {Object.keys(trainerCourses).map((key) => (
                  <CourseDay
                    key={key}
                    date={key}
                    startLoading={() => {
                      setIsLoading(true);
                    }}
                    stopLoading={() => {
                      setIsLoading(false);
                    }}
                    courses={trainerCourses[key]}
                  ></CourseDay>
                ))}
              </Box>
            </ThemeProvider>
            <BottomNavigationBar></BottomNavigationBar>
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};

export default CoursePage;
