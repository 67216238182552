import React, { ReactNode } from "react";

const CourseItemBtn: React.FC<{
  onClickFct: () => void;
  backgroundColor: string;
  btnIcon?: string;
  iconComponent?: ReactNode;
}> = ({ onClickFct, backgroundColor, btnIcon, iconComponent }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <button
        onClick={(event) => {
          event.stopPropagation();
          onClickFct();
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "25px",
          boxShadow: "none",
          width: "50px",
          borderRadius: "var(--borderRadiusMedium)",
          color: "white",
          backgroundColor: backgroundColor,
          pointerEvents: "auto",
          border: "none",
          marginLeft: "10px",
        }}
      >
        {iconComponent ? (
          iconComponent
        ) : (
          <img src={btnIcon} alt={"Icon"} style={{ fill: "white" }}></img>
        )}
      </button>
    </div>
  );
};

export default CourseItemBtn;
