import { Box, Typography } from "@mui/material";
import { Incident } from "../../../models/incident";
import TextImageInput from "../ImageIO/TextImageInput";
import trash from "../../../img/trashWhite.png";
import { useEffect, useState } from "react";
import "../../../css/globalVariables.css";
import { Attachment } from "../../../models/attachment";
import { base64DataToFile, createFileFromURL } from "../../../util/ImageHelper";
import { addUpdateIncident } from "../../../api/course-api";
import SnackbarMessage from "../../Snackbar/SnackbarMessage";

const IncidentItem: React.FC<{
  courseID: string;
  courseName: string;
  incident: Incident;
  onChangeIncident: (incidentUUID: string) => Promise<void>;
  onDeleteIncident: (incidentUUID: string) => Promise<void>;
  onSaveIncident: () => Promise<void>;
  onStartLoading: () => void;
  onStopLoading: () => void;
  onFocusHandler: () => void;
  onBlurHandler: () => void;
}> = ({
  courseID,
  courseName,
  incident,
  onChangeIncident,
  onDeleteIncident,
  onSaveIncident,
  onStartLoading,
  onStopLoading,
  onFocusHandler,
  onBlurHandler,
}) => {
  const trashButton = {
    backgroundColor: "var(--red)",
    borderRadius: "var(--borderRadiusMedium)",
    margin: "10px",
    border: "none",
    height: "50px",
    width: "50px",
  };

  const [incidentObj, setIncidentObj] = useState<Incident>(incident);
  const [incidentAttachments, setIncidentAttachments] = useState<Attachment[]>(
    incident.attachments
  );
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  useEffect(() => {
    if (incident.description !== "") {
      setIsActive(false);
    }
  }, [incident.description]);

  return (
    <>
      <SnackbarMessage
        open={snackBarOpen}
        text={errorMsg}
        onCloseFct={() => {
          setSnackBarOpen(false);
        }}
      ></SnackbarMessage>

      <div
        style={{
          backgroundColor: isActive ? "white" : "var(--orange)",
          border: isActive
            ? incidentObj.isSaved
              ? "var(--borderSaved)"
              : "var(--border)"
            : "",
          borderRadius: "var(--borderRadiusLarge)",
          marginBottom: "20px",
        }}
      >
        <Box
          onClick={() => {
            if (incidentObj.isSaved) {
              setIsActive(!isActive);
            }
          }}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            sx={{
              fontSize: "var(--h4)",
              textTransform: "uppercase",
              fontWeight: "bold",
              margin: "auto 0 auto 15px",
              color: isActive ? "var(--orange)" : "white",
            }}
          >
            {incident.incidentType}
          </Typography>
          <button
            onClick={async (e) => {
              e.stopPropagation();
              onStartLoading();
              await onDeleteIncident(incident.incidentUUID);
            }}
            style={trashButton}
          >
            <img style={{ height: "20px" }} src={trash} alt="" />
          </button>
        </Box>
        {isActive && (
          <TextImageInput
            courseName={courseName}
            onFocusHandler={onFocusHandler}
            onBlurHandler={onBlurHandler}
            incidentUUID={incident.incidentUUID}
            textAreaOnChange={async (
              e: React.ChangeEvent<HTMLTextAreaElement>
            ) => {
              const newIncident: Incident = {
                ...incidentObj,
                description: e.target.value,
                isSaved: false,
              };
              setIncidentObj(newIncident);
              await onChangeIncident(newIncident.incidentUUID);
            }}
            textAreaDefaultValue={incidentObj.description}
            textAreaError={error}
            placeholder="Bitte schildere das Vorkommnis..."
            saveBtnHandler={async (e) => {
              if (incidentObj.description !== "") {
                onStartLoading();
                e.stopPropagation();
                const updateIncident: Incident = {
                  ...incidentObj,
                  isSaved: true,
                };

                const incidentFiles: File[] = [];
                for (const attachment of incidentAttachments) {
                  if (attachment.base64) {
                    incidentFiles.push(
                      await base64DataToFile(
                        attachment.base64,
                        attachment.filename
                      )
                    );
                  } else if (attachment.link) {
                    const file = createFileFromURL(attachment.link);
                    incidentFiles.push(file);
                  } else {
                    incidentFiles.push({} as File);
                  }
                }

                const response = await addUpdateIncident(
                  courseID,
                  updateIncident,
                  incidentFiles
                );
                if (response.status === 200) {
                  setIncidentObj(updateIncident);
                  setError(false);
                  setIsActive(false);
                  await onSaveIncident();
                } else {
                  setError(true);
                  setSnackBarOpen(true);
                  setErrorMsg(
                    "Etwas ist schiefgegangen... Bitte speicher das Vorkommnis erneut"
                  );
                  setIncidentObj({ ...updateIncident, isSaved: false });
                  onStopLoading();
                }
              }
            }}
            saveBtnError={error}
            isItemSaved={incidentObj.isSaved}
            editBtnHandler={() =>
              setIncidentObj({ ...incidentObj, isSaved: false })
            }
            attachments={incidentAttachments}
            onChangeAttachments={(attachments: Attachment[]) => {
              setIncidentAttachments(attachments);
            }}
          ></TextImageInput>
        )}
      </div>
    </>
  );
};

export default IncidentItem;
