import { Paper, ThemeProvider, Typography } from "@mui/material";
import { customTheme } from "../../customTheme";
import { allRoutes } from "../../../enums/all-routes";
import { NavLink } from "react-router-dom";
import { Stack } from "@mui/system";
import calenderActive from "../../../img/calenderActive.png";
import calendar from "../../../img/calendar.png";
import profileActive from "../../../img/profileActive.png";
import profile from "../../../img/profile.png";
import archivActive from "../../../img/archivActive.png";
import archiv from "../../../img/archiv.png";
import classes from "../../../css/BottomNavigationBar.module.css";
import "../../../css/globalVariables.css";

const BottomNavigationBar: React.FC<{}> = () => {
  const pathname = window.location.pathname;

  const links = [
    {
      index: 1,
      pathname: allRoutes.myCourses,
      pathnameAlt: allRoutes.notifyAttendees,
      text: "Meine Kurse",
      imgActive: calenderActive,
      img: calendar,
    },
    {
      index: 2,
      pathname: allRoutes.archive,
      pathnameAlt: allRoutes.archiveItem,
      text: "Archiv",
      imgActive: archivActive,
      img: archiv,
    },

    {
      index: 3,
      pathname: allRoutes.profile,
      pathnameAlt: allRoutes.profileDetails,
      pathnameAlt2: allRoutes.contact,
      text: "Profil",
      imgActive: profileActive,
      img: profile,
    },
  ];

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            boxShadow: "none",
            paddingLeft: "35px",
            paddingRight: "35px",
            borderTop: "1px solid #ececec ",
            borderRadius: "0",
            zIndex: "1000",
          }}
          elevation={3}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={0}
            sx={{ height: "70px", width: "100%" }}
          >
            {links.map((link) => (
              <NavLink
                to={link.pathname}
                className={classes.item}
                key={link.index}
              >
                <img
                  alt=""
                  className={
                    pathname === link.pathname ||
                    pathname.includes(link.pathnameAlt) ||
                    pathname === link.pathnameAlt2
                      ? classes.imgActive
                      : classes.img
                  }
                  src={
                    pathname === link.pathname ||
                    pathname.includes(link.pathnameAlt) ||
                    pathname === link.pathnameAlt2
                      ? link.imgActive
                      : link.img
                  }
                />
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "var(--h4)",
                    textAlign: "center",
                    color:
                      pathname === link.pathname ||
                      pathname.includes(link.pathnameAlt) ||
                      pathname === link.pathnameAlt2
                        ? "var(--orange)"
                        : "var(--grey)",
                  }}
                >
                  {link.text}
                </Typography>
              </NavLink>
            ))}
          </Stack>
        </Paper>
      </ThemeProvider>
    </>
  );
};

export default BottomNavigationBar;
