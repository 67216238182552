import { Box, ThemeProvider, Typography } from "@mui/material";
import "../../../css/globalVariables.css";
import { customTheme } from "../../customTheme";
import { Participant } from "../../../models/participant";
import ArchiveAttendeeItem from "./ArchiveAttendeeItem";
import { useEffect, useState } from "react";
import { Course } from "../../../models/course";

const ArchiveAttendeeList: React.FC<{
  course: Course;
  participants: Participant[];
}> = ({ course, participants }) => {
  const [trueParticipants, setTrueParticipants] = useState(participants);
  const [falseParticipants, setFalseParticipants] = useState(participants);

  const getTrueParticipants = () => {
    const part = participants.filter(
      (participant) => participant.isPresent === true
    );
    setTrueParticipants(part);
  };

  const getFalseParticipants = () => {
    const part = participants.filter(
      (participant) => participant.isPresent === false
    );
    setFalseParticipants(part);
  };
  /* eslint-disable */
  useEffect(() => {
    if (participants) {
      getTrueParticipants();
      getFalseParticipants();
    }
  }, [participants]);
  /* eslint-enable */

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Typography
          sx={{
            fontSize: "var(--h3)",
            color: "var(--grey)",
            fontWeight: "bold",
            margin: "15px",
          }}
        >
          {"Anwesende"}
        </Typography>
        {trueParticipants && (
          <Box
            sx={{
              display: "grid",
              gap: "15px",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {trueParticipants.map((participant) => (
              <ArchiveAttendeeItem
                course={course}
                participant={participant}
                key={participant.id}
              ></ArchiveAttendeeItem>
            ))}
          </Box>
        )}

        {falseParticipants && (
          <>
            <Typography
              sx={{
                fontSize: "var(--h3)",
                color: "var(--grey)",
                fontWeight: "bold",
                margin: "15px",
              }}
            >
              {"Abwesende"}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gap: "15px",
                gridTemplateColumns: "1fr 1fr",
                marginTop: "15px",
              }}
            >
              {falseParticipants.map((participant) => (
                <ArchiveAttendeeItem
                  course={course}
                  participant={participant}
                  key={participant.id}
                ></ArchiveAttendeeItem>
              ))}
            </Box>
          </>
        )}
        {!participants && (
          <Box
            sx={{
              margin: "5px",
              border: "var(--border)",
              borderRadius: "var(--borderRadiusLarge)",
            }}
          >
            <Typography
              sx={{
                padding: "15px",
                color: "var(--grey)",
                fontSize: "var(--h2)",
              }}
            >
              {"Keine Teilnehmenden"}
            </Typography>
          </Box>
        )}
      </ThemeProvider>
    </>
  );
};

export default ArchiveAttendeeList;
