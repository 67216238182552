/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Trainer } from "../models/trainer";
import {
  logoutUser,
  refreshAdminToken,
  verifyAdminTokens,
  verifyTrainerTokens,
} from "../api/auth-api";
import { localStorageKeys } from "../constants/storage-keys";

type AuthContextObj = {
  isAdmin: boolean;
  trainer: Trainer;
  setTrainer: (_: Trainer) => void;
  setIsAdmin: (_: boolean) => void;
  areTokensAndTrainerUuidValid: () => Promise<boolean>;
};

export const AuthContext = React.createContext<AuthContextObj>({
  isAdmin: false,
  trainer: {} as Trainer,
  setTrainer: (_: Trainer) => {},
  setIsAdmin: (_: boolean) => {},
  areTokensAndTrainerUuidValid: async () => {
    return false;
  },
});

const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [trainerIsAdmin, setTrainerIsAdmin] = useState(false);
  const [trainerObj, setTrainerObj] = useState({} as Trainer);

  const setTrainer = (trainer: Trainer) => {
    setTrainerObj(trainer);
  };

  const setIsAdmin = (value: boolean) => {
    setTrainerIsAdmin(value);
  };

  const checkTrainerToken = async () => {
    const accessToken = localStorage.getItem(
      localStorageKeys.trainerAccessToken
    );
    if (!accessToken) {
      return false;
    }

    const {
      accessTokenValid,
      refreshTokenValid,
    }: { accessTokenValid: boolean; refreshTokenValid: boolean } =
      await verifyTrainerTokens(accessToken);

    if (!refreshTokenValid) {
      await logoutUser();
      return false;
    }
    if (!accessTokenValid) {
      await refreshAdminToken();
    }

    return true;
  };

  const checkTrainerUUID = () => {
    const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);

    if (!trainerUUID) {
      return false;
    }
    return true;
  };

  /**
   * Returns true if all authTokens are valid and trainerUUID is set
   */
  const areTokensAndTrainerUuidValid: () => Promise<boolean> = async () => {
    const trainerTokenValid = await checkTrainerToken();
    const trainerUuidValid = checkTrainerUUID();

    return trainerTokenValid && trainerUuidValid;
  };

  useEffect(() => {
    areTokensAndTrainerUuidValid();
  }, []);

  const contextValue: AuthContextObj = {
    isAdmin: trainerIsAdmin,
    trainer: trainerObj,
    setTrainer,
    setIsAdmin: setIsAdmin,
    areTokensAndTrainerUuidValid: areTokensAndTrainerUuidValid,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
