import { createTheme } from "@mui/material/styles";

export const customTheme = createTheme({
  typography: {
    fontFamily: "Tahoma, sans-serif",
    button: {
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      main: "#E3BD40",
    },
    secondary: {
      main: "#607783",
    },
  },
});
