import { Trainer } from "./trainer";
import { Participant } from "./participant";
import { Incident } from "./incident";
import { Attachment } from "./attachment";

export enum CourseStates {
  UPCOMING = "UPCOMING",
  READY_FOR_CHECK_IN = "READY_FOR_CHECK_IN",
  IN_EDIT = "IN_EDIT",
  READ_ONLY = "READ_ONLY",
  CHECK_OUT_COMPLETE = "CHECK_OUT_COMPLETE",
  CANCELED = "CANCELED",
  UNDOCUMENTED = "UNDOCUMENTED",
}

export enum ConditionTypes {
  HALL = "HALL",
  MATERIAL = "MATERIAL",
  TRAINER = "TRAINER",
}

export interface CourseInterface {
  id: string;
  courseID: string;
  state: CourseStates;
  startDate: string;
  endDate: string;
  checkInDate: string;
  checkOutDate: string;
  location: string;
  capacity: number;
  documentingTrainerUUID: string;
  courseName: string;
  isLateDocu: boolean;
  hallConditionValue?: boolean;
  hallConditionDescr?: string;
  hallConditionAttachments?: Attachment[];
  materialConditionValue?: boolean;
  materialConditionDescr?: string;
  materialConditionAttachments?: Attachment[];
  trainerConditionValue?: boolean;
  trainerConditionDescr?: string;
  trainers: Trainer[];
  participants: Participant[];
  incidents: Incident[];
  message?: string;
}

export class Course implements CourseInterface {
  id: string;
  courseID: string;
  courseName: string;
  state: CourseStates;
  startDate: string;
  endDate: string;
  checkInDate: string;
  checkOutDate: string;
  location: string;
  capacity: number;
  documentingTrainerUUID: string;
  isLateDocu: boolean;
  hallConditionValue?: boolean;
  hallConditionDescr?: string;
  hallConditionAttachments?: Attachment[];
  materialConditionValue?: boolean;
  materialConditionDescr?: string;
  materialConditionAttachments?: Attachment[];
  trainerConditionValue?: boolean;
  trainerConditionDescr?: string;
  trainers: Trainer[];
  participants: Participant[];
  incidents: Incident[];
  message?: string;

  constructor(
    id: string,
    courseID: string,
    courseName: string,
    state: CourseStates,
    startDate: string,
    endDate: string,
    checkInDate: string,
    checkOutDate: string,
    location: string,
    documentingTrainerUUID: string,
    trainers: Trainer[],
    participants: Participant[],
    isLateDocu: boolean,
    hallConditionValue: boolean,
    materialConditionValue: boolean,
    trainerConditionValue: boolean,
    hallConditionDescr?: string,
    hallConditionAttachments?: Attachment[],
    materialConditionDescr?: string,
    materialConditionAttachments?: Attachment[],
    trainerConditionDescr?: string,
    message?: string
  ) {
    this.id = id;
    this.courseID = courseID;
    this.courseName = courseName;
    this.state = state;
    this.startDate = startDate;
    this.endDate = endDate;
    this.checkInDate = checkInDate;
    this.checkOutDate = checkOutDate;
    this.location = location;
    this.documentingTrainerUUID = documentingTrainerUUID;
    this.trainers = trainers;
    this.participants = participants;
    this.hallConditionValue = hallConditionValue;
    this.materialConditionValue = materialConditionValue;
    this.trainerConditionValue = trainerConditionValue;
    this.hallConditionDescr = hallConditionDescr ? hallConditionDescr : "";
    this.hallConditionAttachments = hallConditionAttachments
      ? hallConditionAttachments
      : [];
    this.materialConditionDescr = materialConditionDescr
      ? materialConditionDescr
      : "";
    this.materialConditionAttachments = materialConditionAttachments
      ? materialConditionAttachments
      : [];
    this.trainerConditionDescr = trainerConditionDescr
      ? trainerConditionDescr
      : "";
    this.incidents = [];
    this.message = message;
    this.isLateDocu = isLateDocu;
  }
}
