import { List } from "@mui/material";
import { Participant } from "../../../models/participant";
import AttendanceItem from "./AttendanceItem";

const AttendanceList: React.FC<{
  trueParticipantsList: Participant[];
  falseParticipantsList: Participant[];
  onValueChange: (_: string, __: boolean) => void;
  onClickInfoBtn: (_: string) => void;
  isModal: boolean;
}> = ({
  trueParticipantsList,
  falseParticipantsList,
  onValueChange,
  onClickInfoBtn,
  isModal,
}) => {
  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
        }}
      >
        {falseParticipantsList.map((falseParticipant) => (
          <AttendanceItem
            key={falseParticipant.id}
            id={falseParticipant.id}
            firstName={falseParticipant.firstName}
            lastName={falseParticipant.lastName}
            isPresent={falseParticipant.isPresent}
            onClickInfoBtn={onClickInfoBtn}
            onValueChange={isModal ? () => {} : onValueChange}
          ></AttendanceItem>
        ))}
        {trueParticipantsList.map((trueParticipant) => (
          <AttendanceItem
            key={trueParticipant.id}
            id={trueParticipant.id}
            firstName={trueParticipant.firstName}
            lastName={trueParticipant.lastName}
            isPresent={trueParticipant.isPresent}
            onClickInfoBtn={onClickInfoBtn}
            onValueChange={isModal ? () => {} : onValueChange}
          ></AttendanceItem>
        ))}
      </List>
    </>
  );
};

export default AttendanceList;
