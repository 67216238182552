import { Box, ThemeProvider, Typography } from "@mui/material";
import { Course } from "../../../models/course";
import { customTheme } from "../../customTheme";
import "../../../css/globalVariables.css";
import { getDate, getTimeFromDates } from "../../../util/DateFormatHelper";
import NavBackBtn from "../Navigation/NavBackBtn";
import InfoCardTypography from "./InfoCardTypography";

const InfoCard: React.FC<{
  courseObj?: Course;
  backBtnText: string;
  backBtnPath?: string;
  backBtnOnClick?: () => void;
  backBtnGoBack?: boolean;
  textContent?: string;
  content?: React.ReactNode;
  btnChildren: React.ReactNode;
}> = ({
  courseObj,
  backBtnText,
  backBtnPath,
  backBtnOnClick,
  backBtnGoBack,
  textContent,
  content,
  btnChildren,
}) => {
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          onClick={backBtnOnClick}
          sx={{
            margin: "10px 10px 10px 6%",
          }}
        >
          <NavBackBtn
            text={backBtnText}
            destination={backBtnPath}
            goBack={backBtnGoBack}
          ></NavBackBtn>
        </Box>
        {/* Header */}

        <Box
          sx={{
            margin: "20px",
            position: "fixed",
            top: "30px",
            bottom: "50px",
            marginBottom: "auto",
            width: "calc(100% - 40px)",
          }}
        >
          {courseObj && (
            <Box
              sx={{
                bgcolor: "var(--grey)",
                color: "white",
                padding: "10px",
                borderRadius:
                  "var(--borderRadiusLarge) var(--borderRadiusLarge) 0 0",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "var(--h1)", fontWeight: "bold" }}>
                  {courseObj.courseName}
                </Typography>
                <Typography sx={{ fontSize: "var(--h3)", fontWeight: "light" }}>
                  {courseObj.location}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "auto" }}>
                <Typography
                  sx={{
                    fontSize: "var(--h3)",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {getDate(courseObj.startDate)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "var(--h3)",
                    fontWeight: "light",
                    textAlign: "right",
                  }}
                >
                  {getTimeFromDates(courseObj.startDate, courseObj.endDate)}
                </Typography>
              </Box>
            </Box>
          )}
          {/* Main Content */}
          <Box
            sx={{
              borderLeft: "var(--border)",
              borderRight: "var(--border)",
              borderTop: courseObj ? "" : "var(--border)",
              borderRadius: courseObj
                ? ""
                : "var(--borderRadiusLarge) var(--borderRadiusLarge) 0 0",
              height: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            {textContent ? (
              <InfoCardTypography text={textContent}></InfoCardTypography>
            ) : (
              <Box sx={{ margin: "10px" }}>{content}</Box>
            )}
          </Box>
          <Box marginTop={"auto"}>{btnChildren}</Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default InfoCard;
