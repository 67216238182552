import { useEffect, useState, useCallback } from "react";
import { Box, ThemeProvider, Typography } from "@mui/material";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import styles from "../../../css/ConditionItem.module.css";
import { customTheme } from "../../customTheme";
import TextImageInput from "../ImageIO/TextImageInput";
import ConditionBtn from "./ConditionBtn";
import "../../../css/globalVariables.css";
import SnackbarMessage from "../../Snackbar/SnackbarMessage";
import { saveConditionImages, updateCourse } from "../../../api/course-api";
import { Attachment } from "../../../models/attachment";
import { base64DataToFile, createFileFromURL } from "../../../util/ImageHelper";

const ConditionItem: React.FC<{
  courseID: string;
  courseName: string;
  conditionPrefix: string;
  title: string;
  placeholder: string;
  error: boolean;
  onFocusHandler: () => void;
  onBlurHandler: () => void;
  onValidityChange: (_: boolean) => void;
  onValueChange: (_: boolean) => void;
  onDescrChange: (_: string) => void;
  onAttachmentsChange?: (_: Attachment[]) => void;
  onSavedChange: (_: boolean) => void;
  onItemTouched: (_: boolean) => void;
  onDescriptionTouched: (_: boolean) => void;
  onClickEditBtn: () => void;
  itemValue: boolean;
  itemDescr: string;
  itemAttachments?: Attachment[];
  itemSaved: boolean;
  itemTouched: boolean;
  itemDescrTouched: boolean;
  onStartLoading: () => void;
  onStopLoading: () => void;
  disabled: boolean;
}> = ({
  courseID,
  courseName,
  conditionPrefix,
  title,
  placeholder,
  error,
  onFocusHandler,
  onBlurHandler,
  onValidityChange,
  onValueChange,
  onDescrChange,
  onAttachmentsChange,
  onSavedChange,
  onItemTouched,
  onDescriptionTouched,
  onClickEditBtn,
  itemValue,
  itemDescr,
  itemAttachments,
  itemSaved,
  itemTouched,
  itemDescrTouched,
  onStartLoading,
  onStopLoading,
  disabled,
}) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarErrorMsg, setSnackBarErrorMsg] = useState("");

  const isItemValid = useCallback(() => {
    if (itemValue) {
      return true;
    } else {
      if (itemDescr?.trim().length !== 0 && itemSaved) {
        return true;
      } else {
        return false;
      }
    }
  }, [itemValue, itemDescr, itemSaved]);

  useEffect(() => {
    onValidityChange(isItemValid());
  }, [itemValue, itemDescr, itemSaved, onValidityChange, isItemValid]);

  const descriptionHandler = (event: React.FormEvent<HTMLTextAreaElement>) => {
    onDescriptionTouched(true);
    onDescrChange(event.currentTarget.value);
  };

  const conditionMap: Record<string, any> = {
    hallCondition: { hallCondition: true },
    materialCondition: { materialCondition: true },
    trainerCondition: { trainerCondition: true },
  };

  /** Error States */
  const isDescrEmpty: boolean = itemDescrTouched && itemDescr.length === 0;

  const errorState: boolean = error && itemTouched;

  const trueItemState: boolean = itemValue && itemTouched;

  const falseItemState: boolean = !itemValue && itemTouched;

  const descrEmptyErr: boolean = !itemValue && itemTouched && isDescrEmpty;

  const isNotSavedErr: boolean = !itemValue && !isDescrEmpty && !itemSaved;

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          sx={{
            opacity: disabled ? 0.5 : 1,
            border:
              descrEmptyErr || errorState
                ? "1px solid var(--red)"
                : falseItemState && itemSaved
                ? "1px solid var(--orange)"
                : trueItemState
                ? "1px solid var(--green)"
                : "var(--border)",
            borderRadius: "var(--borderRadiusLarge)",
            margin: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "var(--h4)",
              margin: "10px",
              textTransform: "uppercase",
              color: "var(--grey)",
            }}
          >
            {title}
          </Typography>

          <Box sx={{ width: "100%" }}>
            <ConditionBtn
              onClickHandler={() => {
                if (disabled) {
                  return;
                }
                onItemTouched(true);
                onValueChange(false);
                onSavedChange(false);
              }}
              className={
                falseItemState ? styles.falseBtnActive : styles.falseBtn
              }
              style={{
                borderRight: `1px solid ${
                  errorState
                    ? "var(--red)"
                    : falseItemState
                    ? "var(--orange)"
                    : "var(--borderColor)"
                }`,
                borderRadius: falseItemState
                  ? "0"
                  : "0 0 0 var(--borderRadiusLarge)",
                width: itemSaved && falseItemState ? "100%" : "50%",
              }}
              isExtended={falseItemState}
              borderColor={
                errorState
                  ? "var(--red)"
                  : falseItemState
                  ? "var(--orange)"
                  : "var(--borderColor)"
              }
            >
              <PriorityHighRoundedIcon
                sx={
                  falseItemState ? { color: "white" } : { color: "var(--grey)" }
                }
              />
            </ConditionBtn>

            {(!itemSaved || trueItemState || !falseItemState) && (
              <ConditionBtn
                onClickHandler={() => {
                  if (disabled) {
                    return;
                  }
                  onItemTouched(true);
                  onValueChange(true);
                  onSavedChange(false);
                }}
                className={
                  trueItemState ? styles.trueBtnActive : styles.trueBtn
                }
                style={{
                  borderLeft: `1px solid ${
                    errorState ? "var(--red)" : "var(--borderColor)"
                  }`,
                  borderRadius: falseItemState
                    ? "0"
                    : "0 0 var(--borderRadiusLarge) 0",
                }}
                isExtended={falseItemState}
                borderColor={errorState ? "var(--red)" : "var(--borderColor)"}
              >
                <CheckRoundedIcon
                  sx={
                    trueItemState
                      ? { color: "white" }
                      : { color: "var(--grey)" }
                  }
                />
              </ConditionBtn>
            )}
          </Box>

          {falseItemState && (
            <TextImageInput
              courseName={courseName}
              onFocusHandler={onFocusHandler}
              onBlurHandler={onBlurHandler}
              conditionPrefix={conditionPrefix}
              textAreaOnChange={descriptionHandler}
              textAreaDefaultValue={itemDescr}
              textAreaError={itemTouched && isDescrEmpty}
              placeholder={placeholder}
              isItemSaved={itemSaved}
              editBtnHandler={() => {
                if (disabled) {
                  return;
                }
                onSavedChange(false);
                onItemTouched(true);
                onClickEditBtn();
              }}
              saveBtnHandler={async () => {
                if (itemDescr.length === 0) {
                  onValidityChange(false);
                  onSavedChange(false);
                  setSnackBarErrorMsg("Bitte eine kurze Beschreibung eingeben");
                  setOpenSnackBar(true);
                } else {
                  onStartLoading();
                  const updateCourseResponse = await updateCourse(courseID, {
                    [`${conditionPrefix}Value`]: itemValue,
                    [`${conditionPrefix}Descr`]: itemDescr,
                  });
                  if (updateCourseResponse.status !== 200) {
                    onStopLoading();
                    setSnackBarErrorMsg(
                      "Etwas ist schiefgegangen... Bitte speicher den Zustand erneut"
                    );
                    setOpenSnackBar(true);
                    onValidityChange(false);
                    onSavedChange(false);
                    return;
                  }

                  const itemFiles: File[] = [];
                  if (itemAttachments) {
                    for (const attachment of itemAttachments) {
                      if (attachment.base64) {
                        itemFiles.push(
                          await base64DataToFile(
                            attachment.base64,
                            attachment.filename
                          )
                        );
                      } else if (attachment.link) {
                        const file = createFileFromURL(attachment.link);
                        itemFiles.push(file);
                      } else {
                        itemFiles.push({} as File);
                      }
                    }
                  }

                  if (itemFiles.length !== 0) {
                    const saveConditionImgResponse = await saveConditionImages(
                      itemFiles,
                      courseID,
                      conditionMap[conditionPrefix]
                    );
                    if (saveConditionImgResponse.status !== 200) {
                      onStopLoading();
                      setSnackBarErrorMsg(
                        "Beim speichern der Bilder ist etwas schiefgegangen... Bitte speicher den Zustand erneut"
                      );
                      setOpenSnackBar(true);
                      onValidityChange(false);
                      onSavedChange(false);
                      return;
                    }
                  }

                  onValidityChange(true);
                  onSavedChange(true);
                  onStopLoading();
                }
              }}
              saveBtnError={isNotSavedErr}
              attachments={itemAttachments}
              onChangeAttachments={onAttachmentsChange}
            ></TextImageInput>
          )}
        </Box>
      </ThemeProvider>
      <SnackbarMessage
        open={openSnackBar}
        text={snackBarErrorMsg}
        onCloseFct={() => {
          setOpenSnackBar(false);
        }}
      />
    </>
  );
};

export default ConditionItem;
