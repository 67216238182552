import imageCompression from "browser-image-compression";

export async function base64DataToFile(base64Data: string, filename: string) {
  const matches = base64Data.match(/^data:(.*);base64,(.*)$/);

  if (!matches || matches.length !== 3) {
    return null; // Invalid base64 data format
  }

  const contentType = matches[1];
  const base64String = matches[2];

  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const imageFile = new File([byteArray], filename, { type: contentType });

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
  };

  try {
    const compressedFileBlob = await imageCompression(imageFile, options);
    const compressedFile = new File([compressedFileBlob], filename, {
      type: compressedFileBlob.type,
    });
    return compressedFile;
  } catch (error) {
    return imageFile;
  }
}

export function createFileFromURL(url: string) {
  const urlParts = url.split("/");
  const attachmentName = urlParts.slice(-3).join("/");
  const file = new File([""], attachmentName, {
    type: "text/plain",
  });
  return file;
}
