import { List, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import { Course } from "../../../models/course";
import { customTheme } from "../../customTheme";
import CourseItem from "./CourseItem";
import "../../../css/globalVariables.css";
import { dateStringToDate } from "../../../util/DateFormatHelper";

const CourseDay: React.FC<{
  date: string;
  courses: Course[];
  startLoading: () => void;
  stopLoading: () => void;
}> = ({ date, courses, startLoading, stopLoading }) => {
  const getCorrectDateString = (dateString: string) => {
    const areDatesOnSameDay = (date1: Date, date2: Date) => {
      return (
        date1.toISOString().slice(0, 10) === date2.toISOString().slice(0, 10)
      );
    };

    const inputDate: Date = dateStringToDate(dateString);
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    if (areDatesOnSameDay(inputDate, today)) {
      return `Heute | ${dateString}`;
    }
    if (areDatesOnSameDay(inputDate, tomorrow)) {
      return `Morgen | ${dateString}`;
    }
    return dateString;
  };

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Typography
          sx={{
            fontSize: "var(--h4)",
            fontWeight: "bold",
            padding: "15px",
            color: "var(--grey)",
          }}
        >
          {getCorrectDateString(date)}
        </Typography>
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            border: "var(--border)",
            borderRadius: "var(--borderRadiusLarge)",
            padding: "0",
            overflow: "hidden",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {courses.map((course) => (
            <React.Fragment key={course.id}>
              <CourseItem
                key={course.id}
                course={course}
                startLoading={startLoading}
                stopLoading={stopLoading}
              />

              {course.id !== courses.at(-1).id && (
                <hr
                  style={{
                    margin: "1px",
                    border: "none",
                    backgroundColor: "var(--borderColor)",
                    height: "1px",
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </List>
      </ThemeProvider>
    </>
  );
};

export default CourseDay;
