import { Box, ThemeProvider } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { allRoutes, getPathWithCourseID } from "../../enums/all-routes";
import { customTheme } from "../../components/customTheme";
import ConditionItem from "../../components/TrainerApp/Condition/ConditionItem";
import { ConditionTypes, Course } from "../../models/course";
import HeaderCourseDocu from "../../components/TrainerApp/Header/HeaderCourseDocu";
import NavButtons from "../../components/TrainerApp/Navigation/NavButtons";
import { getCourseEntity, updateCourse } from "../../api/course-api";
import { Attachment } from "../../models/attachment";
import { TIMEOUT_MILLIS } from "../../constants/timeout-duration";
import GeneralDocuPage from "./GeneralDocuPage";
import { SnackBarContext } from "../../context/snackbar-context";
import { HttpStatusCode } from "axios";
import { formatSnackBarErrMsg } from "../../util/SnackBarErrorMsgFormatter";

const ConditionPage: React.FC = () => {
  const snackBarCtx = useContext(SnackBarContext);
  const history = useHistory();

  const { courseID } = useParams<{ courseID: string }>();

  const conditionInfo = {
    [ConditionTypes.HALL]: {
      conditionPrefix: "hallCondition",
      title: "Hallenzustand",
      placeholder: "Bitte den Hallenzustand beschreiben...",
    },
    [ConditionTypes.MATERIAL]: {
      conditionPrefix: "materialCondition",
      title: "Materialzustand",
      placeholder: "Bitte den Materialzustand beschreiben...",
    },
    [ConditionTypes.TRAINER]: {
      conditionPrefix: "trainerCondition",
      title: "Alle Trainer anwesend?",
      placeholder: "Bitte eine Erklärung abgeben...",
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const [navBtnVisible, setNavBtnVisible] = useState(true);

  const [course, setCourse] = useState<Course>({} as Course);

  /** hallCondition */
  const [hallConditionValid, setHallConditionValid] = useState(false);
  const [hallErr, setHallErr] = useState(false);
  const [hallValue, setHallValue] = useState(false);
  const [hallDescr, setHallDescr] = useState("");
  const [hallAttachments, setHallAttachments] = useState<Attachment[]>(
    [] as Attachment[]
  );
  const [hallSaved, setHallSaved] = useState<boolean>(true);
  const [hallTouched, setHallTouched] = useState(false);
  const [hallDescrTouched, setHallDescrTouched] = useState(false);
  const [hallDisabled, setHallDisabled] = useState(false);

  /** MaterialZustand */
  const [materialConditionValid, setMaterialConditionValid] = useState(false);
  const [materialErr, setMaterialErr] = useState(false);
  const [materialValue, setMaterialValue] = useState(false);
  const [materialDescr, setMaterialDescr] = useState("");
  const [materialAttachments, setMaterialAttachments] = useState<Attachment[]>(
    [] as Attachment[]
  );
  const [materialSaved, setMaterialSaved] = useState<boolean>(true);
  const [materialTouched, setMaterialTouched] = useState(false);
  const [materialDescrTouched, setMaterialDescrTouched] = useState(false);
  const [materialDisabled, setMaterialDisabled] = useState(false);

  /** TrainerZustand */
  const [trainerConditionValid, setTrainerConditionValid] = useState(false);
  const [trainerErr, setTrainerErr] = useState(false);
  const [trainerValue, setTrainerValue] = useState(false);
  const [trainerDescr, setTrainerDescr] = useState("");

  const [trainerSaved, setTrainerSaved] = useState<boolean>(true);
  const [trainerTouched, setTrainerTouched] = useState(false);
  const [trainerDescrTouched, setTrainerDescrTouched] = useState(false);
  const [trainerDisabled, setTrainerDisabled] = useState(false);

  const [areAllConditionsValid, setAreAllConditionsValid] = useState(false);

  const handleInputFocus = () => {
    setNavBtnVisible(false);
  };

  const handleInputBlur = () => {
    setNavBtnVisible(true);
  };

  const checkSingleConditionAndSetValues = (
    value: boolean,
    descr: string,
    valueSetter: (value: React.SetStateAction<boolean>) => void,
    descrSetter: (value: React.SetStateAction<string>) => void,
    touchSetter: React.Dispatch<React.SetStateAction<boolean>>,
    descrTouchSetter: React.Dispatch<React.SetStateAction<boolean>>,
    attach?: Attachment[],
    attachSetter?: React.Dispatch<React.SetStateAction<Attachment[]>>
  ) => {
    if (!((value === false && descr === "") || (!value && !descr))) {
      touchSetter(true);
      valueSetter(value);
      if (value) {
        descrSetter("");
      } else {
        descrSetter(descr);
        descrTouchSetter(true);
      }
      if (attach && attachSetter) {
        value ? attachSetter([]) : attachSetter(attach);
      }
    }
  };

  /**
   * Checks if the given kursZustandObj includes default values. Sets the values that are not default.
   * @param kursZustandObj
   */
  const checkCourseObjAndSetConditionValues = useCallback(
    (courseObj: Course) => {
      checkSingleConditionAndSetValues(
        courseObj.hallConditionValue,
        courseObj.hallConditionDescr,
        setHallValue,
        setHallDescr,
        setHallTouched,
        setHallDescrTouched,
        courseObj.hallConditionAttachments,
        setHallAttachments
      );
      checkSingleConditionAndSetValues(
        courseObj.materialConditionValue,
        courseObj.materialConditionDescr,
        setMaterialValue,
        setMaterialDescr,
        setMaterialTouched,
        setMaterialDescrTouched,
        courseObj.materialConditionAttachments,
        setMaterialAttachments
      );
      checkSingleConditionAndSetValues(
        courseObj.trainerConditionValue,
        courseObj.trainerConditionDescr,
        setTrainerValue,
        setTrainerDescr,
        setTrainerTouched,
        setTrainerDescrTouched
      );
    },
    [
      setHallValue,
      setHallDescr,
      setHallAttachments,
      setHallTouched,
      setHallDescrTouched,
      setMaterialValue,
      setMaterialDescr,
      setMaterialAttachments,
      setMaterialTouched,
      setMaterialDescrTouched,
      setTrainerValue,
      setTrainerDescr,
      setTrainerTouched,
      setTrainerDescrTouched,
    ]
  );

  const checkAndSetErr = () => {
    if (!hallConditionValid) {
      setHallErr(true);
    }
    if (!materialConditionValid) {
      setMaterialErr(true);
    }
    if (!trainerConditionValid) {
      setTrainerErr(true);
    }
  };

  const startLoadingHandler = () => {
    setIsLoading(true);
  };
  const stopLoadingHandler = () => {
    setIsLoading(false);
  };

  const hallDisableInputs = (value: boolean) => {
    setMaterialDisabled(value);
    setTrainerDisabled(value);
  };

  const materialDisableInputs = (value: boolean) => {
    setHallDisabled(value);
    setTrainerDisabled(value);
  };

  const trainerDisableInputs = (value: boolean) => {
    setHallDisabled(value);
    setMaterialDisabled(value);
  };

  /* eslint-disable */
  useEffect(() => {
    setIsLoading(true);
    getCourseEntity(courseID, snackBarCtx)
      .then((c: Course) => {
        setCourse(c);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
          setShowContent(true);
        }, TIMEOUT_MILLIS);
      });
  }, [courseID]);

  useEffect(() => {
    checkCourseObjAndSetConditionValues(course);
  }, [courseID, course]);
  /* eslint-enable */

  useEffect(() => {
    setAreAllConditionsValid(
      hallConditionValid && materialConditionValid && trainerConditionValid
    );
  }, [
    hallConditionValid,
    materialConditionValid,
    trainerConditionValid,
    setAreAllConditionsValid,
  ]);

  return (
    <>
      <GeneralDocuPage
        isLoading={isLoading}
        children={
          <>
            {showContent && (
              <>
                <ThemeProvider theme={customTheme}>
                  <HeaderCourseDocu
                    course={course}
                    showTopStepper={true}
                    subtitle={"Vorbereitung"}
                    activeStep={1}
                    backBtnText={"Meine Kurse"}
                    backBtnPath={getPathWithCourseID(
                      courseID,
                      allRoutes.cancelDocu
                    )}
                  ></HeaderCourseDocu>
                  <Box
                    sx={{
                      margin: "20px",
                      marginTop: "150px",
                    }}
                  ></Box>
                </ThemeProvider>
                <ConditionItem
                  courseID={courseID}
                  courseName={course.courseName}
                  conditionPrefix={conditionInfo.HALL.conditionPrefix}
                  title={conditionInfo.HALL.title}
                  placeholder={conditionInfo.HALL.placeholder}
                  error={hallErr}
                  onFocusHandler={handleInputFocus}
                  onBlurHandler={handleInputBlur}
                  onValidityChange={(validity: boolean) => {
                    setHallConditionValid(validity);
                    checkAndSetErr();
                    if (validity) {
                      setHallErr(false);
                    }
                  }}
                  onValueChange={(value: boolean) => {
                    setHallValue(value);
                    if (value) {
                      hallDisableInputs(false);
                    }
                    if (!value) {
                      hallDisableInputs(true);
                    }
                  }}
                  onDescrChange={(value: string) => {
                    setHallDescr(value);
                  }}
                  onAttachmentsChange={(attachments: Attachment[]) => {
                    setHallAttachments(attachments);
                  }}
                  onSavedChange={(value: boolean) => {
                    setHallSaved(value);
                    if (value) {
                      hallDisableInputs(false);
                    }
                  }}
                  onItemTouched={(value: boolean) => {
                    setHallTouched(value);
                  }}
                  onDescriptionTouched={(value: boolean) => {
                    setHallDescrTouched(value);
                  }}
                  onClickEditBtn={() => {
                    hallDisableInputs(true);
                  }}
                  itemTouched={hallTouched}
                  itemDescrTouched={hallDescrTouched}
                  itemValue={hallValue}
                  itemDescr={hallDescr}
                  itemAttachments={hallAttachments}
                  itemSaved={hallSaved}
                  onStartLoading={startLoadingHandler}
                  onStopLoading={stopLoadingHandler}
                  disabled={hallDisabled}
                ></ConditionItem>
                <ConditionItem
                  courseID={courseID}
                  courseName={course.courseName}
                  conditionPrefix={conditionInfo.MATERIAL.conditionPrefix}
                  title={conditionInfo.MATERIAL.title}
                  placeholder={conditionInfo.MATERIAL.placeholder}
                  error={materialErr}
                  onFocusHandler={handleInputFocus}
                  onBlurHandler={handleInputBlur}
                  onValidityChange={(value: boolean) => {
                    setMaterialConditionValid(value);
                    checkAndSetErr();
                    if (value) {
                      setMaterialErr(false);
                    }
                  }}
                  onValueChange={(value: boolean) => {
                    setMaterialValue(value);
                    if (value) {
                      materialDisableInputs(false);
                    }
                    if (!value) {
                      materialDisableInputs(true);
                    }
                  }}
                  onDescrChange={(value: string) => {
                    setMaterialDescr(value);
                  }}
                  onAttachmentsChange={(attachments: Attachment[]) => {
                    setMaterialAttachments(attachments);
                  }}
                  onSavedChange={(value: boolean) => {
                    setMaterialSaved(value);
                    if (value) {
                      materialDisableInputs(false);
                    }
                  }}
                  onItemTouched={(value: boolean) => {
                    setMaterialTouched(value);
                  }}
                  onDescriptionTouched={(value: boolean) => {
                    setMaterialDescrTouched(value);
                  }}
                  onClickEditBtn={() => {
                    materialDisableInputs(true);
                  }}
                  itemTouched={materialTouched}
                  itemDescrTouched={materialDescrTouched}
                  itemValue={materialValue}
                  itemDescr={materialDescr}
                  itemAttachments={materialAttachments}
                  itemSaved={materialSaved}
                  onStartLoading={startLoadingHandler}
                  onStopLoading={stopLoadingHandler}
                  disabled={materialDisabled}
                ></ConditionItem>
                <ConditionItem
                  courseID={courseID}
                  courseName={course.courseName}
                  conditionPrefix={conditionInfo.TRAINER.conditionPrefix}
                  title={conditionInfo.TRAINER.title}
                  placeholder={conditionInfo.TRAINER.placeholder}
                  error={trainerErr}
                  onFocusHandler={handleInputFocus}
                  onBlurHandler={handleInputBlur}
                  onValidityChange={(value: boolean) => {
                    setTrainerConditionValid(value);
                    checkAndSetErr();
                    if (value) {
                      setTrainerErr(false);
                    }
                  }}
                  onValueChange={(value: boolean) => {
                    setTrainerValue(value);
                    if (value) {
                      trainerDisableInputs(false);
                    }
                    if (!value) {
                      trainerDisableInputs(true);
                    }
                  }}
                  onDescrChange={(value: string) => {
                    setTrainerDescr(value);
                  }}
                  onSavedChange={(value: boolean) => {
                    setTrainerSaved(value);
                    if (value) {
                      trainerDisableInputs(false);
                    }
                  }}
                  onItemTouched={(value: boolean) => {
                    setTrainerTouched(value);
                  }}
                  onDescriptionTouched={(value: boolean) => {
                    setTrainerDescrTouched(value);
                  }}
                  onClickEditBtn={() => {
                    trainerDisableInputs(true);
                  }}
                  itemTouched={trainerTouched}
                  itemDescrTouched={trainerDescrTouched}
                  itemValue={trainerValue}
                  itemDescr={trainerDescr}
                  itemSaved={trainerSaved}
                  onStartLoading={startLoadingHandler}
                  onStopLoading={stopLoadingHandler}
                  disabled={trainerDisabled}
                ></ConditionItem>

                {navBtnVisible && (
                  <NavButtons
                    leftPathname={getPathWithCourseID(
                      courseID,
                      allRoutes.cancelDocu
                    )}
                    leftText={"Zurück"}
                    rightBtnOnClick={
                      areAllConditionsValid
                        ? async () => {
                            const response = await updateCourse(courseID, {
                              hallConditionValue: hallValue,
                              hallConditionDescr: hallDescr,
                              hallConditionAttachments: "",
                              materialConditionValue: materialValue,
                              materialConditionDescr: materialDescr,
                              materialConditionAttachments: "",
                              trainerConditionValue: trainerValue,
                              trainerConditionDescr: trainerDescr,
                            });
                            if (response.status !== HttpStatusCode.Ok) {
                              snackBarCtx.openSnackbar(
                                formatSnackBarErrMsg(
                                  response.status,
                                  `Beim speichern der Daten ist ein Fehler aufgetreten. Bitte versuche es erneut oder lade die Seite neu...`
                                )
                              );
                              return;
                            }
                            history.push(
                              getPathWithCourseID(
                                courseID,
                                allRoutes.attendance
                              )
                            );
                          }
                        : () => {
                            checkAndSetErr();
                          }
                    }
                    rightBtnDisabled={!areAllConditionsValid}
                    rightText={"Weiter"}
                  ></NavButtons>
                )}
              </>
            )}
          </>
        }
      ></GeneralDocuPage>
    </>
  );
};

export default ConditionPage;
