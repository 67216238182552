import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import arrow from "../../../img/arrowLeftActive.png";
import classes from "../../../css/NavBackBtn.module.css";
import "../../../css/globalVariables.css";

const NavBackBtn: React.FC<{
  text: string;
  destination?: string;
  onClickFct?: () => void;
  goBack?: boolean;
}> = ({ text, destination, goBack, onClickFct }) => {
  const history = useHistory();
  return (
    <>
      <Box
        onClick={async () => {
          if (onClickFct) {
            onClickFct();
          }
          if (goBack) {
            history.goBack();
          } else {
            history.push(destination);
          }
        }}
        className={classes.button}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "150px",
          }}
        >
          <img alt="arrow" src={arrow} className={classes.img} />
          <Typography
            sx={{
              marginLeft: "10px",
              marginTop: "auto",
              marginBottom: "auto",
              fontSize: "var(--h1)",
              fontWeight: "bold",
              color: "var(--orange)",
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default NavBackBtn;
