import { Alert, Snackbar } from "@mui/material";

const SnackbarMessage: React.FC<{
  open: boolean;
  text: string;
  onCloseFct: () => void;
  messageType?: "error" | "success";
}> = ({ open, text, onCloseFct, messageType = "error" }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClick={onCloseFct}
      onClose={onCloseFct}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert severity={messageType} sx={{ width: "100%" }}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
