import { Box, ListItem, ThemeProvider, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { customTheme } from "../../customTheme";
import rectangle from "../../../img/rectangle.png";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import info from "../../../img/info2.png";
import "../../../css/globalVariables.css";

const AttendanceItem: React.FC<{
  id: string;
  firstName: string;
  lastName: string;
  isPresent: boolean;
  onValueChange: (_: string, __: boolean) => void;
  onClickInfoBtn: (_: string) => void;
}> = ({
  id,
  firstName,
  lastName,
  isPresent,
  onValueChange,
  onClickInfoBtn,
}) => {
  const [isParticipantPresent, setIsParticipantPresent] = useState(isPresent);
  const [isSwiped, setIsSwiped] = useState(false);
  const [startX, setStartX] = useState<number | null>(null);
  const [currentX, setCurrentX] = useState<number | null>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  /* eslint-disable */
  useEffect(() => {
    onValueChange(id, isParticipantPresent);
  }, [id, isParticipantPresent]);
  /* eslint-enable */

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0];
    setStartX(touch.clientX);
    setCurrentX(touch.clientX);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (startX === null) return;
    const touch = e.touches[0];
    setCurrentX(touch.clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (
      currentX !== null &&
      startX !== null &&
      currentX < startX &&
      startX - currentX > 50
    ) {
      setIsSwiped(true);
    } else {
      setIsSwiped(false);
    }
    setStartX(null);
    setCurrentX(null);
  };

  const handleTouchCancel = () => {
    setStartX(null);
    setCurrentX(null);
  };

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          sx={{
            margin: "8px 20px 0 20px",
            border: "var(--border)",
            borderRadius: "var(--borderRadiusLarge)",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              position: "relative",
            }}
          >
            <button
              onClick={() => onClickInfoBtn(id)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "110px",
                height: "100%",
                backgroundColor: "var(--green)",
                color: "white",
                position: "absolute",
                right: "0",
                top: "0",
                borderRadius: "none",
                boxShadow: "none",
                border: "none",
              }}
            >
              <img
                src={info}
                alt="Info"
                style={{
                  height: "30px",
                  marginLeft: "auto",
                  marginRight: "10px",
                }}
              />
            </button>
            <div
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              onTouchCancel={handleTouchCancel}
              ref={boxRef}
              style={{
                width: "1000px",
                transition: "transform 0.3s ease-in-out",
                transform: `translateX(${isSwiped ? -70 : 0}px)`,
                backgroundColor: "white",
                borderRadius: "var(--borderRadiusLarge)",
                zIndex: "50",
              }}
            >
              <ListItem
                sx={{
                  padding: "7px 0 7px 10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "var(--h1)",
                      fontWeight: "bold",
                      margin: "3px",
                      color: "var(--grey)",
                    }}
                  >
                    {firstName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "var(--h4)",
                      margin: "3px",
                      color: "var(--grey)",
                    }}
                  >
                    {lastName}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "50%" }}
                >
                  <button
                    onClick={() => {
                      setIsParticipantPresent(!isParticipantPresent);
                    }}
                    style={{
                      width: "90%",
                      border: "var(--border)",
                      borderRadius: "var(--borderRadiusLarge)",
                      padding: "12px",
                      backgroundColor: isParticipantPresent
                        ? "var(--green)"
                        : "white",
                    }}
                  >
                    <CheckRoundedIcon
                      sx={{
                        color: isParticipantPresent ? "white" : "var(--grey)",
                      }}
                    />
                  </button>
                  <img
                    src={rectangle}
                    alt=""
                    style={{
                      height: "35px",
                      margin: "auto",
                    }}
                  />
                </Box>
              </ListItem>
            </div>
          </div>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AttendanceItem;
