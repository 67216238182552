import "../../css/globalVariables.css";
import { ThemeProvider } from "@emotion/react";
import { customTheme } from "../../components/customTheme";
import { Box, Dialog, Typography } from "@mui/material";
import BottomNavigationBar from "../../components/TrainerApp/Navigation/BottomNavigationBar";
import { useContext, useEffect, useState } from "react";
import { sendEmail } from "../../api/email-api";
import { allRoutes } from "../../enums/all-routes";
import { AuthContext } from "../../context/auth-context";
import { Trainer } from "../../models/trainer";
import InfoCard from "../../components/TrainerApp/InfoCard/InfoCard";
import InfoCardYesNoBtns from "../../components/TrainerApp/InfoCard/InfoCardYesNoBtns";
import InfoCardSingleBtn from "../../components/TrainerApp/InfoCard/InfoCardSingleBtn";
import { useHistory } from "react-router-dom";
import InfoCardTypography from "../../components/TrainerApp/InfoCard/InfoCardTypography";
import TextFieldWithChars from "../../components/TextFieldWithChars";
import NavBackBtn from "../../components/TrainerApp/Navigation/NavBackBtn";

const ContactPage: React.FC = () => {
  const MIN_CHARS_TEXTFIELD = 20;
  const MAX_CHARS_TEXTFIELD = 400;
  const MIN_CHARS_ISSUEFIELD = 5;
  const MAX_CHARS_ISSUEFIELD = 50;
  const receiver = "mitglieder@sportfreunde.de";
  const history = useHistory();

  const authContext = useContext(AuthContext);
  const trainer: Trainer = authContext.trainer;

  const [issueTitle, setIssueTitle] = useState("");
  const [issueText, setIssueText] = useState("");
  const [allValid, setAllValid] = useState(false);
  const [showQuestionDialog, setShowQuestionDialog] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [infoDialogText, setInfoDialogText] = useState("");

  /* eslint-disable */
  useEffect(() => {
    handleAllValid();
  }, [issueTitle, issueText]);
  /* eslint-enable */

  const handleAllValid = () => {
    setAllValid(
      issueTitle.length !== 0 && issueText.length >= MIN_CHARS_TEXTFIELD
    );
  };

  async function handleSendBtn() {
    const sender = `"${trainer.firstName} ${trainer.lastName} - Sportfreunde 06 e.V"`;

    const mailTitle = `${trainer.firstName} ${trainer.lastName} - ${issueTitle}`;

    const mailText = `${issueText}<hr/> "Die Email wurde mit der Kontaktseite der Trainerapp verschickt. Antworten werden nicht an den Trainer weitergeleitet. Bitte kontaktiere diesen daher auf direktem Wege.`;

    const result = await sendEmail(sender, receiver, [], mailTitle, mailText);

    setInfoDialogText(
      result.success === true
        ? "Deine Nachricht wurde erfolgreich an die Administration gesendet."
        : `Deine Nachricht konnte nicht gesendet werden. ${result.message}`
    );

    setShowQuestionDialog(false);
    setShowInfoDialog(true);
  }

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          sx={{
            margin: "20px",
          }}
        >
          <NavBackBtn
            text={"Profil"}
            destination={allRoutes.profile}
          ></NavBackBtn>
          <Typography
            sx={{
              margin: "20px 20px 0px 0px",
              fontWeight: "bold",
              fontSize: "var(--h0)",
              color: "var(--grey)",
            }}
          >
            {"Kontaktformular"}
          </Typography>
          <Box sx={{ margin: "0px 20px 80px 20px" }}>
            <TextFieldWithChars
              placeholder={"Betreff"}
              minChars={MIN_CHARS_ISSUEFIELD}
              maxChars={MAX_CHARS_ISSUEFIELD}
              rows={1}
              onChange={(text: string) => {
                setIssueTitle(text);
              }}
            />

            <TextFieldWithChars
              placeholder={
                "Schreibe hier dein Anliegen an die Administrastion."
              }
              minChars={MIN_CHARS_TEXTFIELD}
              maxChars={MAX_CHARS_TEXTFIELD}
              rows={11}
              onChange={(text: string) => {
                setIssueText(text);
              }}
            />

            <button
              style={{
                backgroundColor: allValid
                  ? "var(--orange)"
                  : "var(--lightOrange)",
                color: "white",
                width: "100%",
                marginTop: "20px",
                boxShadow: "none",
                height: "50px",
                fontWeight: "bold",
                border: "none",
                borderRadius: "var(--borderRadiusLarge)",
              }}
              onClick={() => {
                if (allValid) {
                  setShowQuestionDialog(true);
                }
              }}
            >
              {"Absenden"}
            </button>
          </Box>
        </Box>

        <Dialog fullScreen open={showQuestionDialog} keepMounted>
          <InfoCard
            backBtnText={"Profil"}
            backBtnPath={allRoutes.profile}
            content={
              <InfoCardTypography
                text={
                  "Möchtest du diese Nachricht an die Administration senden?"
                }
              />
            }
            btnChildren={
              <InfoCardYesNoBtns
                yesBtnText={"Ja"}
                noBtnText={"Nein"}
                yesBtnOnClick={() => setShowQuestionDialog(false)}
                noBtnOnClick={() => handleSendBtn()}
              ></InfoCardYesNoBtns>
            }
          ></InfoCard>
        </Dialog>
        <Dialog fullScreen open={showInfoDialog} keepMounted>
          <InfoCard
            backBtnText={"Profil"}
            backBtnPath={allRoutes.profile}
            content={<InfoCardTypography text={infoDialogText} />}
            btnChildren={
              <InfoCardSingleBtn
                btnText={"Zurück"}
                btnOnClick={() => {
                  setShowInfoDialog(false);
                  history.push(allRoutes.profile);
                }}
              ></InfoCardSingleBtn>
            }
          ></InfoCard>
        </Dialog>
      </ThemeProvider>
      <BottomNavigationBar />
    </>
  );
};
export default ContactPage;
