import { Box, ThemeProvider, Typography } from "@mui/material";
import { customTheme } from "../../customTheme";
import "../../../css/globalVariables.css";

const ProfileTextfield: React.FC<{
  textName: string;
  textInfo: string;
}> = ({ textName, textInfo }) => {
  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            border: "var(--border)",
            borderRadius: "var(--borderRadiusLarge)",
            marginTop: "15px",
          }}
        >
          <Typography
            sx={{
              margin: "15px",
              marginLeft: "25px",
              textTransform: "uppercase",
              fontSize: "var(--h4)",
              width: "90px",
              color: "var(--grey)",
            }}
          >
            {textName}
          </Typography>
          <Typography
            sx={{
              marginTop: "auto",
              marginBottom: "auto",
              fontWeight: "bold",
              fontSize: "var(--h4)",
              color: "var(--grey)",
            }}
          >
            {textInfo}
          </Typography>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default ProfileTextfield;
