import { Box, Dialog, ThemeProvider, Typography } from "@mui/material";
import { customTheme } from "../../customTheme";
import HeaderCourseDocu from "../Header/HeaderCourseDocu";
import "../../../css/globalVariables.css";
import ArchiveConditionItem from "./ArchiveConditionItem";
import ArchiveAttendeeList from "./ArchiveAttendeeList";
import BottomNavigationBar from "../Navigation/BottomNavigationBar";
import { Course } from "../../../models/course";
import ArchiveIncidentItem from "./ArchiveIncidentItem";
import { CourseStates } from "../../../models/course";
import { Incident } from "../../../models/incident";

const ArchiveItemDialog: React.FC<{
  course: Course;
  isOpen: boolean;
  onCloseDialog: () => void;
}> = ({ course, isOpen, onCloseDialog }) => {
  const isCourseMessageEmpty = (courseMsg: string) => {
    if (courseMsg === undefined) {
      return;
    }
    if (courseMsg === null || courseMsg.length === 0 || courseMsg === "") {
      return true;
    }
    return false;
  };

  const isCourseIncidentsEmpty = (courseIncidents: Incident[] | undefined) => {
    if (courseIncidents === undefined) {
      return true;
    }
    if (courseIncidents.length === 0) {
      return true;
    }
    return false;
  };

  const isCourseStateCanceled = (courseState: CourseStates) => {
    if (courseState === undefined) {
      return false;
    }
    return courseState === CourseStates.CANCELED;
  };

  return (
    <>
      <Dialog fullScreen open={isOpen} keepMounted>
        <ThemeProvider theme={customTheme}>
          <HeaderCourseDocu
            course={course}
            isArchive={true}
            subtitle="Vorbereitung"
            activeStep={1}
            showTopStepper={false}
            backBtnText="Archiv"
            backBtnOnClick={() => {
              onCloseDialog();
            }}
          ></HeaderCourseDocu>
          <Box
            sx={{
              margin: "20px",
              marginBottom: "100px",
              marginTop: "120px",
            }}
          >
            {!isCourseMessageEmpty(course.message) && (
              <>
                <Typography
                  sx={{
                    fontSize: "var(--h3)",
                    color: "var(--grey)",
                    fontWeight: "bold",
                    margin: "15px",
                  }}
                >
                  {isCourseStateCanceled(course.state)
                    ? "Abgesagt"
                    : "Nachricht"}
                </Typography>
                <Typography
                  sx={{
                    padding: "15px",
                    border: "var(--border)",
                    borderRadius: "var(--borderRadiusLarge)",
                    color: "var(--grey)",
                    fontSize: "var(--p0)",
                  }}
                >
                  {course.message}
                </Typography>
              </>
            )}
            {!isCourseStateCanceled(course.state) && (
              <>
                <Typography
                  sx={{
                    fontSize: "var(--h3)",
                    color: "var(--grey)",
                    fontWeight: "bold",
                    margin: "15px",
                  }}
                >
                  {"Vorbereitung"}
                </Typography>
                <ArchiveConditionItem
                  key={"hallCondition"}
                  title={"Hallenzustand"}
                  description={course.hallConditionDescr}
                  attachments={course.hallConditionAttachments}
                  value={course.hallConditionValue}
                ></ArchiveConditionItem>
                <ArchiveConditionItem
                  key={"materialCondition"}
                  title={"Materialzustand"}
                  description={course.materialConditionDescr}
                  attachments={course.materialConditionAttachments}
                  value={course.materialConditionValue}
                ></ArchiveConditionItem>
                <ArchiveConditionItem
                  key={"trainerCondition"}
                  title={"Alle Trainer anwesend?"}
                  description={course.trainerConditionDescr}
                  value={course.trainerConditionValue}
                ></ArchiveConditionItem>

                <ArchiveAttendeeList
                  course={course}
                  participants={course.participants}
                />
                {!isCourseIncidentsEmpty(course.incidents) && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "var(--h3)",
                        color: "var(--grey)",
                        fontWeight: "bold",
                        margin: "15px",
                      }}
                    >
                      {"Vorkommnisse"}
                    </Typography>
                    {course.incidents &&
                      course.incidents.map((incident) => (
                        <ArchiveIncidentItem
                          title={incident.incidentType}
                          description={incident.description}
                          attachments={incident.attachments}
                          key={incident.incidentUUID}
                        ></ArchiveIncidentItem>
                      ))}
                  </>
                )}
                <BottomNavigationBar />
              </>
            )}
          </Box>
        </ThemeProvider>
      </Dialog>
    </>
  );
};

export default ArchiveItemDialog;
